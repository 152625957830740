@use 'sass:map';
@use '@material/dialog' as mdc-dialog;
@use '@material/dialog/dialog-theme' as mdc-dialog-theme;
@use '@material/typography' as mdc-typography;
@use '@angular/material' as mat;
@use 'core/palette';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-dialog-container {
      $surface: palette.$ftd-bg-light;
      $on-surface: palette.$ftd-white;
      $text-emphasis-high: 1;
      $text-emphasis-medium: 1;

      @include mdc-dialog-theme.theme(
        (
          container-color: $surface,
          with-divider-divider-color: rgba($on-surface, mdc-dialog.$scroll-divider-opacity),
          subhead-color: rgba($on-surface, $text-emphasis-high),
          supporting-text-color: rgba($on-surface, $text-emphasis-medium),
        )
      );
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    .mat-mdc-dialog-container {
      $styles: mdc-typography.$styles;
      $headline6: map.get($styles, headline6);

      @include mdc-dialog-theme.theme(
        (
          subhead-font: map.get($headline6, font-family),
          subhead-line-height: map.get($styles, headline6, line-height),
          subhead-size: map.get($styles, headline6, font-size),
          subhead-weight: map.get($styles, headline6, font-weight),
          subhead-tracking: map.get($styles, headline6, letter-spacing),
          supporting-text-font: map.get($styles, body2, font-family),
          supporting-text-line-height: map.get($styles, body2, line-height),
          supporting-text-size: map.get($styles, body2, font-size),
          supporting-text-weight: map.get($styles, body2, font-weight),
          supporting-text-tracking: map.get($styles, body2, letter-spacing),
        )
      );
    }
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);
}

@mixin ftd-dialog($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-dialog') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }

    .cdk-overlay-dark-backdrop {
      backdrop-filter: blur(2px);
      background: map.get(palette.$ftd-color-map, shadow);
    }

    mat-dialog-container {
      background-color: transparent;
    }
  }
}
