@use '@angular/material' as mat;
@use 'helpers/scrollbars';

@mixin ftd-base($theme) {
  @include scrollbars.scrollbars;

  html,
  body {
    font-family: mat.font-family($theme);
    height: 100%;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  body {
    margin: 0;
  }

  .hidden {
    visibility: hidden;
  }

  .sr-only {
    display: none;
  }
}
