@use '@angular/material' as mat;
@use 'core/palette';
@use 'core/typography';
@use 'core/mixins';
@use 'sass:map';

$button-colors: map.get(palette.$ftd-buttons-map, colors);

@mixin custom-ftd-buttons() {
  .mat-mdc-button.ftd-button {
    align-items: center;
    display: flex;
    font-family: mat.font-family(typography.$ftd-typography);
    font-weight: mat.font-weight(typography.$ftd-typography, 'body-1');
    justify-content: center;
    letter-spacing: 0;
    text-transform: uppercase;

    @include mixins.private-apply-button-colors($button-colors);

    &:disabled,
    &:disabled:hover {
      background: palette.$ftd-grey-400;
      border: 1px solid palette.$ftd-grey-400;
      color: palette.$ftd-grey-500;
      cursor: default;
    }

    .mat-icon {
      font-size: 24px;
      height: 24px;
      width: 24px;
    }

    &-icon-left {
      margin-right: 6px;
    }

    &-icon-right {
      margin-left: 6px;
    }

    &-size-l {
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 16px;
      height: 48px;
      min-width: 81px;
      padding: 12px;
    }

    &-size-m {
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 14px;
      height: 40px;
      min-width: 66px;
      padding: 12px 8px;
    }

    &-size-s {
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 14px;
      height: 32px;
      min-width: 62px;
      padding: 8px 6px;
    }

    &-only-icon {
      min-width: 38px;

      .mat-icon {
        margin: 0;
      }
    }

    &-fill-width {
      margin: auto;
      width: 100%;
    }

    .mdc-button__label {
      display: inline-flex;

      span {
        display: inline-flex;
      }
    }
  }
}
