@use 'core/palette';

.backdrop-background {
  backdrop-filter: blur(2px);
  background: palette.$ftd-bg-popup-shade;
}

.ftd-modal {
  &-primary .mat-dialog-container {
    background-color: palette.$ftd-bg-light;
    color: palette.$ftd-white;
  }

  &-secondary .mat-dialog-container {
    background-color: palette.$ftd-white;
    color: palette.$ftd-black;
  }

  &-tertiary .mat-dialog-container {
    background-color: palette.$ftd-table-alt;
    color: palette.$ftd-bg-light;
  }
}
