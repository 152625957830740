@use 'mdc/textfield' as mdc-textfield;
@use '@material/theme/theme' as mdc-theme;
@use '@material/typography' as mdc-typography;
@use 'mdc/textfield/variables' as mdc-textfield-variables;
@use '@angular/material' as mat;
@use 'core/palette';

@mixin private-form-field-subscript() {
  // Wrapper for the hints and error messages.
  .mat-mdc-form-field-subscript-wrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    left: 0;
    padding: 0 8px;
    position: absolute;
    right: 0;
    top: 4px;
  }

  .mat-mdc-form-field-subscript-dynamic-size {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      position: static;
    }
  }

  .mat-mdc-form-field-bottom-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .custom-message {
      margin-right: auto;
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    content: '';
    display: none;
  }

  .mat-mdc-form-field-bottom-align.mat-mdc-form-field-subscript-dynamic-size::before {
    content: unset;
  }

  .mat-mdc-form-field-hint-end {
    order: 1;
  }

  // Clears the floats on the hints. This is necessary for the hint animation to work.
  .mat-mdc-form-field-hint-wrapper {
    display: flex;
  }

  // Spacer used to make sure start and end hints have enough space between them.
  .mat-mdc-form-field-hint-spacer {
    flex: 0;
  }

  // Single error message displayed beneath the form field underline.
  .mat-mdc-form-field-error {
    display: block;
  }
}

@mixin private-form-field-subscript-color() {
  // MDC does not have built-in error treatment.
  .mat-mdc-form-field-error {
    @include mdc-theme.prop(color, mdc-textfield.$error);
  }

  .mat-mdc-form-field-subscript-wrapper {
    @include mdc-theme.prop(color, palette.$ftd-grey-500);
  }

  :where(.mat-form-field-invalid) .mat-mdc-form-field-subscript-wrapper .custom-message {
    @include mdc-theme.prop(color, mdc-textfield.$error);
  }
}

// We need to define our own typography for the subscript because we don't include MDC's
// helper text in our MDC based form field
@mixin private-form-field-subscript-typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);

  // The subscript wrapper has a minimum height to avoid that the form-field
  // jumps when hints or errors are displayed.
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    @include mdc-typography.typography(caption, $query: mat.$private-mdc-typography-styles-query);
  }
}
