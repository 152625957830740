@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';
@use '@material/switch/switch-theme' as mdc-switch-theme;
@use '@material/theme/color-palette' as mdc-color-palette;
@use 'mdc/form-field/mixins' as mdc-form-field;
@use 'core/palette' as ftd-colors;

// Generates all color mapping for the properties that only change based on the theme.
@function _get-theme-base-map($is-dark) {
  $on-surface: if($is-dark, ftd-colors.$ftd-white, ftd-colors.$ftd-white);
  $hairline: if($is-dark, ftd-colors.$ftd-grey-400, ftd-colors.$ftd-grey-400);
  $on-surface-variant: if($is-dark, ftd-colors.$ftd-white, ftd-colors.$ftd-white);
  $on-surface-state-content: if($is-dark, ftd-colors.$ftd-white, ftd-colors.$ftd-white);
  $disabled-handle-color: if($is-dark, ftd-colors.$ftd-white, ftd-colors.$ftd-white);
  $icon-color: if($is-dark, transparent, transparent);

  @return (
    disabled-selected-handle-color: $disabled-handle-color,
    disabled-unselected-handle-color: $disabled-handle-color,

    disabled-selected-track-color: $on-surface,
    disabled-unselected-track-color: $on-surface,
    unselected-focus-state-layer-color: $on-surface,
    unselected-pressed-state-layer-color: $on-surface,
    unselected-hover-state-layer-color: $on-surface,

    unselected-focus-track-color: $on-surface,
    unselected-hover-track-color: $on-surface,
    unselected-pressed-track-color: $on-surface,
    unselected-track-color: $on-surface,

    unselected-focus-handle-color: $hairline,
    unselected-hover-handle-color: $hairline,
    unselected-pressed-handle-color: $hairline,

    handle-surface-color: $icon-color,
    unselected-handle-color: $hairline,

    selected-icon-color: $icon-color,
    disabled-selected-icon-color: $icon-color,
    disabled-unselected-icon-color: $icon-color,
    unselected-icon-color: $icon-color
  );
}

// Generates the mapping for the properties that change based on the slide toggle color.
@function _get-theme-color-map($color-palette, $is-dark) {
  $primary: ftd-colors.$ftd-primary;
  $state-content: ftd-colors.$ftd-primary;
  $inverse: #5e738f;

  @return (
    selected-focus-state-layer-color: $primary,
    selected-handle-color: $primary,
    selected-hover-state-layer-color: $primary,
    selected-pressed-state-layer-color: $primary,

    selected-focus-handle-color: $state-content,
    selected-hover-handle-color: $state-content,
    selected-pressed-handle-color: $state-content,

    selected-focus-track-color: $inverse,
    selected-hover-track-color: $inverse,
    selected-pressed-track-color: $inverse,
    selected-track-color: $inverse
  );
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $is-dark: map.get($config, is-dark);
  $foreground: map.get($config, foreground);

  @include mat.private-using-mdc-theme($config) {
    // MDC's switch doesn't support a `color` property. We add support
    // for it by adding a CSS class for accent and warn style.
    .mat-mdc-slide-toggle {
      @include mdc-form-field.core-styles($query: mat.$private-mdc-theme-styles-query);
      @include mdc-switch-theme.theme(_get-theme-base-map($is-dark));

      // MDC should set the disabled color on the label, but doesn't, so we do it here instead.
      .mdc-switch--disabled + label {
        color: mat.get-color-from-palette($foreground, disabled-text);
      }

      &.mat-primary {
        @include mdc-switch-theme.theme(_get-theme-color-map($primary, $is-dark));
      }

      &.mat-accent {
        @include mdc-switch-theme.theme(_get-theme-color-map($accent, $is-dark));
      }

      &.mat-warn {
        @include mdc-switch-theme.theme(_get-theme-color-map($warn, $is-dark));
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-form-field.core-styles($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  .mat-mdc-slide-toggle {
    @include mdc-switch-theme.theme(mdc-switch-theme.density($density-scale));
  }
}

@mixin ftd-slide-toggle($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-slide-toggle') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
