@use 'sass:map';
@use '@material/tooltip/plain-tooltip-theme';
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/typography/typography' as mdc-typography;
@use '@angular/material' as mat;
@use 'core/palette';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-tooltip {
      @include plain-tooltip-theme.theme(
        (
          container-color: palette.$ftd-bg-elevation,
          supporting-text-color: palette.$ftd-white,
        )
      );
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    .mat-mdc-tooltip {
      @include plain-tooltip-theme.theme(
        (
          supporting-text-font: mdc-typography.get-font(caption),
          supporting-text-size: mdc-typography.get-size(caption),
          supporting-text-weight: mdc-typography.get-weight(caption),
          supporting-text-tracking: mdc-typography.get-tracking(caption),
        )
      );
    }
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);
}

@mixin ftd-tooltip($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-tooltip') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
