@use 'sass:map';
@use '@angular/material' as mat;
@use '@material/circular-progress/circular-progress-theme' as mdc-circular-progress-theme;
@use 'core/palette';

@mixin _palette-styles($color) {
  @include mdc-circular-progress-theme.theme(
    (
      active-indicator-color: $color,
    )
  );
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-progress-spinner {
      @include _palette-styles(palette.$ftd-white);

      &.mat-accent {
        @include _palette-styles(palette.$ftd-info-200);
      }

      &.mat-warn {
        @include _palette-styles(palette.$ftd-risk-100);
      }
    }
  }
}

@mixin typography($config-or-theme) {
  // extends typography
}

@mixin density($config-or-theme) {
  // extends density
}

@mixin ftd-spinner($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-progress-spinner') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
