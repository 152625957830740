@use 'core/palette';

.ftd-modal-header-icon-color {
  &.info {
    color: palette.$ftd-info-100;
  }

  &.success {
    color: palette.$ftd-opportunity-100;
  }

  &.error {
    color: palette.$ftd-risk-100;
  }

  &.warning {
    color: palette.$ftd-caution-100;
  }
}
