div#mv-filter-container {
  .price-editor-filled-toggle-label {
    margin-right: 8px;
  }

  mat-form-field#ftd-dropdown-mv-filter-price-editor-filled {
    margin-bottom: 16px;
  }

  mat-form-field#ftd-dropdown-mv-filter-planning-horizon {
    visibility: hidden;
  }

  mat-form-field {
    margin-bottom: 8px;
    max-height: 40px;
    width: 160px;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.filter-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.size {
  height: calc(100vh - 328px);

  // Reason: In lower resolutions scroll on X axis appeared
  overflow-x: hidden;
  overflow-y: auto;
  width: 174px;
}
