// stylelint-disable selector-class-pattern --
// Selector '.mdc-*' should only be used in this project.

@use '@material/feature-targeting/feature-targeting';
@use '@material/rtl/rtl';
@use '@material/theme/theme';
@use '@material/typography/typography';
@use './variables';

@mixin core-styles($query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);
  $feat-structure: feature-targeting.create-target($query, structure);

  .mdc-form-field {
    @include typography.typography(body2, $query);

    @include feature-targeting.targets($feat-color) {
      @include theme.property(color, text-primary-on-background);
    }

    @include feature-targeting.targets($feat-structure) {
      align-items: center;
      display: inline-flex;
      vertical-align: middle;

      &[hidden] {
        display: none;
      }
    }

    > label {
      @include feature-targeting.targets($feat-structure) {
        @include rtl.reflexive-property(margin, 0, auto);
        @include rtl.reflexive-property(padding, variables.$item-spacing, 0);

        order: 0;
      }
    }
  }

  .mdc-form-field--nowrap {
    > label {
      @include feature-targeting.targets($feat-structure) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .mdc-form-field--align-end {
    > label {
      @include feature-targeting.targets($feat-structure) {
        @include rtl.reflexive-property(margin, auto, 0);
        @include rtl.reflexive-property(padding, 0, variables.$item-spacing);

        order: -1;
      }
    }
  }

  .mdc-form-field--space-between {
    @include feature-targeting.targets($feat-structure) {
      justify-content: space-between;
    }

    > label {
      @include feature-targeting.targets($feat-structure) {
        margin: 0;

        @include rtl.rtl {
          // RTL needed for specificity
          margin: 0;
        }
      }
    }
  }
}
