@use 'core/palette';

app-macroeconomics-page {
  .mat-mdc-form-field {
    background-color: palette.$ftd-white;
    color: palette.$ftd-black;
  }

  ftd-tab-group {
    .mat-mdc-tab-label-container {
      flex-grow: 0;
    }
  }
}
