@use 'core/palette';
@use '@angular/material' as mat;

app-create-discount-modal {
  app-ftd-date-picker {
    .mat-mdc-text-field-wrapper {
      height: 40px;

      @include mat.icon-button-density(-4);

      .mat-mdc-icon-button {
        color: white;
      }
    }
  }
}
