@use '@angular/material' as mat;
@use '@material/theme/theme' as mdc-theme;
@use 'core/palette' as ftd-colors;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-optgroup-label {
      // Since this will usually be rendered in an overlay,
      // we have to explicitly set the default color.
      @include mdc-theme.prop(color, ftd-colors.$ftd-white);
      @include mdc-theme.prop(background, ftd-colors.$ftd-table-selected);
      @include mdc-theme.prop(min-height, 16px);
      @include mdc-theme.prop(padding, 0 8px);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);
}

@mixin ftd-optgroup($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-optgroup') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
