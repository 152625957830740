@use '@angular/material' as mat;
@use 'sass:map';
@use 'mdc/list/list' as mdc-list;
@use './interactive-list-theme';
@use './list-option-theme';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: mat.get-color-from-palette(map.get($config, primary));
  $accent: mat.get-color-from-palette(map.get($config, accent));
  $warn: mat.get-color-from-palette(map.get($config, warn));

  @include mat.private-using-mdc-theme($config) {
    // MDC's state styles are tied in with their ripple. Since we don't use the MDC
    // ripple, we need to add the hover, focus and selected states manually.
    @include interactive-list-theme.private-interactive-list-item-state-colors($config);
    @include mdc-list.without-ripple($query: mat.$private-mdc-theme-styles-query);

    .mat-mdc-list-option {
      @include list-option-theme.private-list-option-color-override($config, $primary, primary);
    }

    .mat-mdc-list-option.mat-accent {
      @include list-option-theme.private-list-option-color-override($config, $accent, secondary);
    }

    .mat-mdc-list-option.mat-warn {
      @include list-option-theme.private-list-option-color-override($config, $warn, error);
    }
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  @include mat.private-disable-mdc-fallback-declarations {
    .mat-mdc-list-item {
      @include mdc-list.one-line-item-density($density-scale);
      @include mdc-list.two-line-item-density($density-scale);
      @include mdc-list.three-line-item-density($density-scale);
    }

    @include list-option-theme.private-list-option-density-styles($density-scale);
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-list.without-ripple($query: mat.$private-mdc-typography-styles-query);
    @include list-option-theme.private-list-option-typography-styles;
  }

  // According to the public spec this should be subtitle-1.
  // However, body-1 and subtitle-1 are nearly identical in the public spec,
  // and the Google-specific spec states that it should be body-1.
  // For consistency, we use body-1 for both public and Google internal.
  .mat-mdc-list-item .mdc-list-item__primary-text {
    @include mat.typography-level($config, body-2);
  }
}

@mixin ftd-list($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-list') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
