// stylelint-disable selector-class-pattern --
// Selector '.mdc-*' should only be used in this project.

@use '@material/theme/theme';
@use '@material/rtl/rtl';
@use '@material/feature-targeting/feature-targeting';
@use '@material/typography/typography';

// Public mixins

@mixin character-counter-core-styles($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  // postcss-bem-linter: define text-field-character-counter

  .mdc-text-field-character-counter {
    @include typography.typography(caption, $query: $query);
    @include typography.text-baseline($top: 16px, $query: $query);

    @include feature-targeting.targets($feat-structure) {
      // Keep flex item align to trailing side on absence of helper text.
      @include rtl.reflexive-box(margin, left, auto);
      @include rtl.reflexive-box(padding, left, 16px);

      white-space: nowrap;
    }
  }

  // postcss-bem-linter: end
}

///
/// Customizes the color of the character counter associated with an enabled text field.
/// @param {Color} $color - The desired character counter color.
///
@mixin character-counter-color($color, $query: feature-targeting.all()) {
  &:not(.mdc-text-field--disabled) {
    @include character-counter-color_($color, $query);
  }
}

///
/// Customizes the color of the character counter associated with a disabled text field.
/// @param {Color} $color - The desired character counter color.
///
@mixin disabled-character-counter-color($color, $query: feature-targeting.all()) {
  &.mdc-text-field--disabled {
    @include character-counter-color_($color, $query);
  }
}

@mixin character-counter-position($xOffset, $yOffset, $query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  .mdc-text-field-character-counter {
    @include feature-targeting.targets($feat-structure) {
      @include rtl.reflexive-position(right, $xOffset);

      bottom: $yOffset;
      position: absolute;
    }
  }
}

// Private mixins

@mixin character-counter-color_($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  // Character counter is placed inside mdc-textfield element (for textarea variant) or
  // inside helper line which is sibling to mdc-textfield.
  .mdc-text-field-character-counter,
  + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    @include feature-targeting.targets($feat-color) {
      @include theme.property(color, $color);
    }
  }
}
