@use 'core/palette';

app-subsidies-and-taxation {
  app-ftd-multi-dropdown {
    .mat-mdc-select-value-text {
      color: palette.$ftd-black;
    }
  }
  app-ftd-input {
    .mat-form-field {
      margin-bottom: 0px;
      width: 100%;
    }
    .mat-mdc-form-field {
      background-color: palette.$ftd-white;
      color: palette.$ftd-black;
    }
    .mat-mdc-form-field.mat-focused.mat-primary {
      color: palette.$ftd-black;
    }
    .mdc-notched-outline {
      background-color: palette.$ftd-white;
    }
  }

  mat-date-range-input {
    display: block;
    width: 0px;

    &:focus {
      border: 2px solid palette.$ftd-info-100;
    }

    .mat-date-range-input-container {
      align-items: center;
      display: flex;
      padding: 2px 4px;
      width: 170px;

      .mat-date-range-input-wrapper {
        color: palette.$ftd-grey-700;
        height: 26px;
        width: 80px;

        input {
          border: 2px solid transparent;
          height: 26px;
          margin: 0;

          &:focus {
            border: 2px solid transparent;
          }

          &.input-locked {
            pointer-events: none;
          }
        }
      }

      .mat-date-range-input-separator {
        color: palette.$ftd-grey-700;
        opacity: 1;
        z-index: 4;
      }
    }

    .mat-date-range-input-mirror {
      color: palette.$ftd-grey-700;
    }
  }
}

app-ftd-input {
  .mat-mdc-form-field-icon-prefix > .mat-icon {
    padding: 0 4px;
  }
}
