@use '@angular/material' as mat;
@use 'core/palette';
@use 'core/functions';
@use 'sass:map';

@mixin ftd-actions-toolbar {
  $types: (
    'info': palette.$ftd-info-100,
    'success': palette.$ftd-opportunity-100,
    'error': palette.$ftd-risk-100,
    'warning': palette.$ftd-caution-100,
  );

  mat-snack-bar-container {
    &.mat-mdc-snack-bar-container.ftd-actions-toolbar {
      box-sizing: border-box;
      display: block;
      margin: 0 functions.to-rem(40);
      max-width: 70vw;
      min-height: functions.to-rem(56);
      min-width: 70vw;
      padding: 0 functions.to-rem(16) functions.to-rem(16);
      transform-origin: center;

      > div {
        background-color: palette.$ftd-bg-dark;
        box-shadow: 0 0 functions.to-rem(6) 0 rgb(0 0 0 / 25%);
        min-width: 100%;
        width: 100%;
      }
    }

    app-ftd-snackbar p {
      margin-block: 0.2rem 0;
      text-transform: none;
    }

    @each $type, $color in $types {
      &.#{$type} .ftd-snackbar-icon {
        color: $color;
      }
    }
  }
}
