@use '@angular/material' as mat;
@use 'core/palette';

@mixin color($config-or-theme) {
  .mat-divider {
    border-top-color: palette.$ftd-alternative;
  }

  .mat-divider-vertical {
    border-right-color: palette.$ftd-alternative;
  }
}

@mixin typography($config-or-theme) {
  // extends typography
}

@mixin density($config-or-theme) {
  // extends density
}

@mixin ftd-divider($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-divider') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
