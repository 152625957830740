@use '@material/icon-button' as mdc-icon;
@use 'core/palette' as ftd-colors;

$traffic-lights-color: 'green' ftd-colors.$ftd-positive ftd-colors.$ftd-opportunity-100,
  'red' ftd-colors.$ftd-negative ftd-colors.$ftd-risk-100, 'grey' ftd-colors.$ftd-grey-400 ftd-colors.$ftd-grey-700,
  'trafficLightNotAvailable' ftd-colors.$ftd-grey-400 ftd-colors.$ftd-grey-700;
$traffic-lights-arrow: 'up' 0deg, 'down' 180deg, 'keep' 90deg 'trafficLightNotAvailable' 0deg;

div.ftd-tab-label-traffic-light {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  &.small {
    height: 10px;
    width: 10px;
  }

  &.overview {
    height: 14px;
    width: 14px;
  }

  mat-icon {
    @include mdc-icon.icon-size(16px, 16px, 0);
    @include mdc-icon.ink-color(ftd-colors.$ftd-white);
  }

  @each $name, $bg-color, $icon-color in $traffic-lights-color {
    &.#{$name} {
      background-color: $bg-color;

      mat-icon {
        color: $icon-color;
      }
    }
  }

  @each $name, $rotation in $traffic-lights-arrow {
    &.#{$name} {
      mat-icon {
        transform: rotate($rotation);
      }
    }
  }
}
