@use 'mdc/ripple/functions' as mdc-ripple-functions;
@use 'mdc/textfield' as mdc-textfield;

// MDC text-field uses `@material/ripple` in order to show a focus and hover effect for
// text-fields. This is unnecessary because the ripples bring in a lot of unnecessary
// styles and runtime code while the actual goal for the text-field is simply showing a
// focus and hover effect. The ripples will unnecessarily provide CSS selectors and JS
// runtime code for launching interaction ripples at pointer location. This is not needed
// for the text-field, so we create our own minimal focus overlay styles. Our focus overlay
// uses the exact same logic to compute the colors as in the default MDC text-field ripples.
@mixin private-form-field-focus-overlay() {
  .mat-mdc-form-field-focus-overlay {
    bottom: 0;
    left: 0;
    opacity: 0;

    // Make sure we don't block click on the prefix/suffix.
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@mixin private-form-field-focus-overlay-color() {
  $focus-opacity: mdc-ripple-functions.states-opacity(mdc-textfield.$ink-color, focus);
  $hover-opacity: mdc-ripple-functions.states-opacity(mdc-textfield.$ink-color, hover);

  .mat-mdc-form-field-focus-overlay {
    background-color: mdc-textfield.$ink-color;
  }

  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: $hover-opacity;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: $focus-opacity;
  }
}
