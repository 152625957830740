@use 'core/palette';

#edit-prices-form {
  #values-form-container {
    background-color: palette.$ftd-bg-medium;
    display: flex;
    justify-content: space-between;
    padding: 16px;

    .mdc-text-field--focused {
      background-color: palette.$ftd-black;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      color: palette.$ftd-white;
      height: 24px;
      margin-right: 8px;
      padding: 0;
      width: 24px;
    }
  }
}
