@use '@angular/material' as mat;
@use 'core/palette';
@use 'sass:map';

@mixin ftd-notifier {
  $types: (
    'info': palette.$ftd-info-100,
    'success': palette.$ftd-opportunity-100,
    'error': palette.$ftd-risk-100,
    'warning': palette.$ftd-caution-100,
  );

  mat-snack-bar-container {
    &.mat-mdc-snack-bar-container {
      box-sizing: border-box;
      display: block;
      max-width: 480px;
      min-height: 56px;
      min-width: 240px;
      padding: 16px;
      transform-origin: center;
    }

    app-ftd-snackbar p {
      margin-block: 0.2rem 0;
      text-transform: none;
    }

    @each $type, $color in $types {
      &.#{$type} .ftd-snackbar-icon {
        color: $color;
      }
    }
  }
}
