@use 'sass:map';

// Main Colors
$ftd-primary: #7b9ac0;

// Neutral Colors
$ftd-white: #fff;
$ftd-grey-100: #f3f4f7;
$ftd-grey-200: #f2f2f2;
$ftd-grey-300: #e0e1e1;
$ftd-grey-400: #d6d6d6;
$ftd-grey-500: #a8a8a7;
$ftd-grey-600: #6f6f6f;
$ftd-grey-700: #494949;
$ftd-grey-800: #2f2f2f;
$ftd-black: #000;

// Background & Tables Colors
$ftd-bg-dark: #151829;
$ftd-bg-medium: #303854;
$ftd-bg-light: #1e2339;
$ftd-bg-popup-shade: #fff 0.5;
$ftd-bg-elevation: #282f47;
$ftd-bg-light-dark: #252c46;
$ftd-alternative: #565b76;
$ftd-table-alt: #33354e;
$ftd-table-selected: #565b76;
$ftd-table-hover: #313b54;
$ftd-shadow: #0a0e32;

// Performance Indicators Colors
$ftd-risk-100: #d92424;
$ftd-risk-200: #8b0c00;
$ftd-opportunity-100: #078007;
$ftd-opportunity-200: #005800;
$ftd-caution-100: #f6c800;
$ftd-caution-200: #daaa00;
$ftd-info-100: #008ff0;
$ftd-info-200: #0071c5;
$ftd-alert-dark: #0b0d10;
$ftd-alert-light: #dcdee1;

// Status pending Colors
$ftd-status-pending-100: #f8d260;
$ftd-status-pending-200: #d79e00;
$ftd-status-pending-300: #815515;
$ftd-status-pending-400: #393021;

// Positive and Negative Indicators
$ftd-positive: #8cc59a;
$ftd-opportunity-900: #293c42;
$ftd-negative: #d47d7e;
$ftd-risk-900: #3a232b;
$ftd-attention: #ffe38e;
$ftd-focus: #9ec7ff;

// Chart Colors
// Main
$ftd-chart-main-blue: #52a2eb;
$ftd-chart-main-light-blue: #8cc4f9;
$ftd-chart-main-turquoise: #79ddc0;
$ftd-chart-main-yellow: #f8c237;

// Secondary
$ftd-chart-secondary-orange: #f28f33;
$ftd-chart-secondary-pink: #ea68a6;
$ftd-chart-secondary-purple: #b867ea;
$ftd-chart-secondary-green: #58ad6d;

// Today color
$ftd-chart-today: #74ebf2;

// Brand
$ftd-brand-bmw: #0070c0;
$ftd-brand-mercedes: #cb4339;
$ftd-brand-audi: #77933c;
$ftd-brand-mini: #000;

// Positive and Negative in text
$ftd-chart-positive: #84e49c;
$ftd-chart-negative: #ff454a;

// Role badges
$ftd-role-admin-color: #5c3eab;
$ftd-role-admin-background: #eadefc;
$ftd-role-approver-color: #0e68ab;
$ftd-role-approver-background: #cce5fc;
$ftd-role-price_manager-color: #a1447a;
$ftd-role-price_manager-background: #f9d8ea;
$ftd-role-reader-color: #108989;
$ftd-role-reader-background: #cef6f6;
$ftd-role-lead-color: #9e5a30;
$ftd-role-lead-background: #fadfb8;

// Material color map
$ftd-color-map: (
  page-bg: $ftd-bg-light,
  background: transparent,
  foreground: $ftd-white,
  hint: $ftd-grey-500,
  disabled: $ftd-grey-700,
  text: $ftd-white,
  placeholder: $ftd-grey-600,
  shadow: rgba($ftd-black, 0.5),
  ftd: (
    text: $ftd-white,
    light: $ftd-bg-light,
    background: $ftd-bg-elevation,
    disabled: $ftd-grey-400,
    hover: $ftd-alternative,
    pressed: $ftd-bg-dark,
  ),
  default: (
    color: $ftd-white,
    border: $ftd-white,
    icon: $ftd-white,
    label: $ftd-grey-500,
  ),
  accent: (
    color: $ftd-info-200,
    border: $ftd-info-200,
    icon: $ftd-white,
    label: $ftd-grey-500,
  ),
  warn: (
    color: $ftd-risk-100,
    border: $ftd-risk-100,
    icon: $ftd-risk-100,
    label: $ftd-risk-100,
  ),
  light-shades: (
    positive: $ftd-positive,
    negative: $ftd-negative,
    attention: $ftd-attention,
    focus: $ftd-focus,
  ),
);
$ftd-buttons-map: (
  colors: (
    'base': (
      'base': (
        'background': transparent,
        'border-color': transparent,
        'color': $ftd-white,
      ),
      'hover': (
        'background': transparent,
        'border-color': transparent,
        'color': $ftd-white,
      ),
      'active': (
        'background': transparent,
        'border-color': transparent,
        'color': $ftd-white,
      ),
    ),
    'primary': (
      'base': (
        'background': $ftd-primary,
        'border-color': $ftd-primary,
        'color': $ftd-white,
      ),
      'hover': (
        'background': $ftd-white,
        'border-color': $ftd-white,
        'color': $ftd-primary,
      ),
      'active': (
        'background': $ftd-table-alt,
        'border-color': $ftd-table-alt,
        'color': $ftd-white,
      ),
    ),
    'secondary': (
      'base': (
        'background': transparent,
        'border-color': $ftd-white,
        'color': $ftd-white,
      ),
      'hover': (
        'background': $ftd-white,
        'border-color': $ftd-white,
        'color': $ftd-table-alt,
      ),
      'active': (
        'background': $ftd-table-alt,
        'border-color': $ftd-white,
        'color': $ftd-white,
      ),
    ),
    'terciary': (
      'base': (
        'background': transparent,
        'border-color': $ftd-primary,
        'color': $ftd-primary,
      ),
      'hover': (
        'background': $ftd-white,
        'border-color': $ftd-white,
        'color': $ftd-table-alt,
      ),
      'active': (
        'background': $ftd-table-alt,
        'border-color': $ftd-primary,
        'color': $ftd-primary,
      ),
    ),
    'quarternary': (
      'base': (
        'background': transparent,
        'border-color': transparent,
        'color': $ftd-white,
      ),
      'hover': (
        'background': $ftd-white,
        'border-color': $ftd-white,
        'color': $ftd-table-alt,
      ),
      'active': (
        'background': $ftd-table-alt,
        'border-color': $ftd-table-alt,
        'color': $ftd-white,
      ),
    ),
    'green': (
      'base': (
        'background': $ftd-opportunity-100,
        'border-color': $ftd-opportunity-100,
        'color': $ftd-white,
      ),
      'hover': (
        'background': $ftd-white,
        'border-color': $ftd-white,
        'color': $ftd-opportunity-100,
      ),
      'active': (
        'background': $ftd-opportunity-100,
        'border-color': $ftd-opportunity-100,
        'color': $ftd-white,
      ),
    ),
    'red': (
      'base': (
        'background': $ftd-risk-100,
        'border-color': $ftd-risk-100,
        'color': $ftd-white,
      ),
      'hover': (
        'background': $ftd-white,
        'border-color': $ftd-white,
        'color': $ftd-risk-100,
      ),
      'active': (
        'background': $ftd-risk-100,
        'border-color': $ftd-risk-100,
        'color': $ftd-white,
      ),
    ),
  ),
);
