@use 'core/palette';

table#scenario-list-table.mat-mdc-table {
  background: palette.$ftd-bg-dark;

  .mat-sort-header-arrow {
    color: palette.$ftd-white;
  }

  .mat-mdc-row .mat-mdc-cell,
  .mat-mdc-header-row .mat-mdc-header-cell {
    border-bottom: 1px solid palette.$ftd-alternative;
    color: palette.$ftd-white;
    cursor: pointer;
    font-size: 1rem;
  }

  .mat-mdc-header-cell {
    background-color: palette.$ftd-bg-dark;
  }

  .mat-mdc-row,
  .mat-mdc-header-row {
    background-color: palette.$ftd-bg-dark;

    &:hover {
      background-color: palette.$ftd-alternative;
    }
  }

  .scenario-delete {
    display: flex;
    justify-content: flex-end;
  }

  .mat-mdc-row.scenario-selected {
    background-color: palette.$ftd-alternative;
  }

  .mat-mdc-row.mat-no-data-row .mat-mdc-cell {
    border: none;
    cursor: default;
  }
}
