@use '@angular/material' as mat;
@use 'mdc/textfield' as mdc-textfield;
@use 'mdc/floating-label' as mdc-floating-label;
@use 'mdc/notched-outline' as mdc-notched-outline;
@use 'mdc/line-ripple' as mdc-line-ripple;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/typography/typography' as mdc-typography;
@use './form-field-density';
@use './form-field-subscript';
@use './form-field-focus-overlay';
@use './form-field-native-select';

// Mixin that overwrites the default MDC text-field color styles to be based on
// the given theme palette. The MDC text-field is styled using `primary` by default.
@mixin _color-styles($palette-name, $query: mat.$private-mdc-theme-styles-query) {
  $orig-focused-label-color: mdc-textfield.$focused-label-color;

  @include mdc-textfield.caret-color($palette-name, $query);
  @include mdc-textfield.line-ripple-color($palette-name, $query);

  .mdc-text-field--focused {
    @include mdc-textfield.focused_($query);
  }

  .mdc-text-field--invalid {
    @include mdc-textfield.invalid_($query);
  }

  .mdc-text-field--outlined {
    @include mdc-textfield.focused-outline-color($palette-name, $query);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    @include mdc-textfield.without-ripple($query: mat.$private-mdc-theme-styles-query);
    @include mdc-floating-label.core-styles($query: mat.$private-mdc-theme-styles-query);
    @include mdc-notched-outline.core-styles($query: mat.$private-mdc-theme-styles-query);
    @include mdc-line-ripple.core-styles($query: mat.$private-mdc-theme-styles-query);
    @include form-field-subscript.private-form-field-subscript-color;
    @include form-field-focus-overlay.private-form-field-focus-overlay-color;
    @include form-field-native-select.private-form-field-native-select-color($config);

    .mat-mdc-form-field.mat-accent {
      @include _color-styles(secondary);
    }

    .mat-mdc-form-field.mat-warn {
      @include _color-styles(error);
    }

    // This fixes an issue where the notch appears to be thicker than the rest of the outline when
    // zoomed in on Chrome. The border inconsistency seems to be some kind of rendering artifact
    // that arises from a combination of the fact that the notch contains text, while the leading
    // and trailing outline do not, combined with the fact that the border is semi-transparent.
    // Experimentally, I discovered that adding a transparent left border fixes the inconsistency.
    // Note: class name is repeated to achieve sufficient specificity over the various MDC states.
    // (hover, focus, etc.)
    .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field {
      &.mat-mdc-form-field .mdc-notched-outline__notch {
        border-left: 1px solid transparent;
      }
    }

    [dir='rtl'] {
      .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field {
        &.mat-mdc-form-field .mdc-notched-outline__notch {
          border-left: none;
          border-right: 1px solid transparent;
        }
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-textfield.without-ripple($query: mat.$private-mdc-typography-styles-query);
    @include mdc-floating-label.core-styles($query: mat.$private-mdc-typography-styles-query);
    @include mdc-notched-outline.core-styles($query: mat.$private-mdc-typography-styles-query);
    @include mdc-line-ripple.core-styles($query: mat.$private-mdc-typography-styles-query);
    @include form-field-subscript.private-form-field-subscript-typography($config);

    // MDC uses `subtitle1` for the input value, placeholder and floating label. The spec
    // shows `body1` for text fields though, so we manually override the typography.
    // Note: Form controls inherit the typography from the parent form field.
    .mat-mdc-form-field,
    .mat-mdc-floating-label {
      @include mdc-typography.typography(body2, $query: mat.$private-mdc-typography-styles-query);
    }

    .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
      line-height: 1.25rem;
    }

    // Above, we updated the floating label to use the `body1` typography level. The MDC notched
    // outline overrides this accidentally (only when the label floats) to a `rem`-based value.
    // This results in different label widths when floated/docked and ultimately breaks the notch
    // width as it has been measured in the docked state (where `body1` is applied). We try to
    // unset these styles set by the `mdc-notched-outline`:
    // https://github.com/material-components/material-components-web/blob/master/packages/mdc-notched-outline/_mixins.scss#L272-L292.
    .mat-mdc-form-field .mdc-text-field--outlined {
      // For the non-upgraded notch label (i.e. when rendered on the server), also
      // use the correct `body1` typography level.
      .mdc-floating-label--float-above {
        font-size: calc(#{mdc-typography.get-size(body2)} * var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        font-size: mdc-typography.get-size(body2);
      }
    }
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  @include form-field-density.private-form-field-density($density-scale);
  @include form-field-subscript.private-form-field-subscript;

  app-ftd-input mat-form-field {
    margin-bottom: 16px;
  }

  app-ftd-input mat-form-field,
  app-ftd-textarea mat-form-field {
    .mat-mdc-text-field-wrapper {
      padding-left: 8px;
      padding-right: 8px;

      textarea {
        min-height: 22px;
      }
    }

    .mdc-text-field--focused {
      background-color: #000;
      border-radius: 4px;
    }
  }
}

@mixin ftd-form-field($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-form-field') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
