@use 'sass:map';
@use '@angular/material' as mat;
@use '@material/data-table/data-table' as mdc-data-table;
@use '@material/data-table' as mdc-data-table-theme;
@use 'core/palette' as ftd-colors;
@use 'table-flex-styles';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  // Save original values of MDC global variables. We need to save these so we can restore the
  // variables to their original values and prevent unintended side effects from using this mixin.
  $orig-selected-row-fill-color: mdc-data-table-theme.$selected-row-fill-color;
  $orig-divider-color: mdc-data-table-theme.$divider-color;
  $orig-row-hover-fill-color: mdc-data-table-theme.$row-hover-fill-color;
  $orig-header-row-text-color: mdc-data-table-theme.$header-row-text-color;
  $orig-row-text-color: mdc-data-table-theme.$row-text-color;
  $orig-stroke-color: mdc-data-table-theme.$stroke-color;

  @include mat.private-using-mdc-theme($config) {
    mdc-data-table-theme.$divider-color: ftd-colors.$ftd-alternative;
    mdc-data-table-theme.$header-row-text-color: ftd-colors.$ftd-white;
    mdc-data-table-theme.$row-hover-fill-color: ftd-colors.$ftd-table-hover;
    mdc-data-table-theme.$row-text-color: ftd-colors.$ftd-white;
    mdc-data-table-theme.$selected-row-fill-color: ftd-colors.$ftd-table-selected;
    mdc-data-table-theme.$stroke-color: ftd-colors.$ftd-alternative;

    @include mdc-data-table.table-styles($query: mat.$private-mdc-theme-styles-query);
  }
  mdc-data-table-theme.$divider-color: $orig-divider-color;
  mdc-data-table-theme.$header-row-text-color: $orig-header-row-text-color;
  mdc-data-table-theme.$row-hover-fill-color: $orig-row-hover-fill-color;
  mdc-data-table-theme.$row-text-color: $orig-row-text-color;
  mdc-data-table-theme.$selected-row-fill-color: $orig-selected-row-fill-color;
  mdc-data-table-theme.$stroke-color: $orig-stroke-color;

  .mat-mdc-table {
    background: ftd-colors.$ftd-bg-light;
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-data-table.table-styles($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  .mat-mdc-table {
    @include mdc-data-table-theme.density($density-scale, $query: mat.$private-mdc-base-styles-query);

    .mdc-data-table__row {
      height: 40px;
    }

    @include mat.icon-button-density(-3);
  }
}

@mixin ftd-table($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-table') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }

    @include table-flex-styles.private-table-flex-styles;
  }
}
