@use 'core/palette';
@use 'sass:color';

.ftd-toggle-button-container {
  background: color.adjust(palette.$ftd-white, $alpha: -0.8);
  border-radius: 30px;
  display: inline-flex;
  position: relative;

  &:not(.disabled):hover {
    background: color.adjust(palette.$ftd-white, $alpha: -0.7);
  }

  & label {
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    padding: 6px 16px;
    position: relative;
    transition: color 0.25s ease-in-out;
    z-index: 2;

    &.active,
    &.inactive {
      color: palette.$ftd-white;
      text-align: center;

      &.checked {
        background: palette.$ftd-white;
        color: palette.$ftd-black;
      }
    }

    mat-icon.ftd-toggle-button-active-icon,
    mat-icon.ftd-toggle-button-inactive-icon {
      margin-right: 4px;
    }
  }

  &.disabled {
    cursor: pointer;
    opacity: 0.3;

    label {
      cursor: default;
    }
  }
}
