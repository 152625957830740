@use 'mdc/menu-surface/mixins' as mdc-menu-surface;
@use 'mdc/list/list' as mdc-list;
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    @include mdc-menu-surface.core-styles(mat.$private-mdc-theme-styles-query);
    @include mdc-list.without-ripple(mat.$private-mdc-theme-styles-query);
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-menu-surface.core-styles(mat.$private-mdc-typography-styles-query);

    .mat-mdc-autocomplete-panel {
      // Note that we include this private mixin, because the public one adds
      // a bunch of styles that we aren't using for the autocomplete panel.
      @include mdc-list.list-base(mat.$private-mdc-typography-styles-query);
    }
  }
}

@mixin density($config-or-theme) {
  // extends density
}

@mixin ftd-autocomplete($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-autocomplete') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
