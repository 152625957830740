@use 'sass:map';
@use '@angular/material' as mat;
@use '@material/theme/theme-color' as mdc-theme-color;
@use 'mdc/menu-surface' as mdc-menu-surface;
@use 'mdc/list/list' as mdc-list;
@use '@material/typography' as mdc-typography;
@use 'core/palette' as ftd-colors;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $default: map.get(ftd-colors.$ftd-color-map, default);
  $accent: map.get(ftd-colors.$ftd-color-map, accent);
  $warn: map.get(ftd-colors.$ftd-color-map, warn);

  @include mat.private-using-mdc-theme($config) {
    $disabled-color: map.get(ftd-colors.$ftd-color-map, disabled);

    @include mdc-menu-surface.core-styles(mat.$private-mdc-theme-styles-query);
    @include mdc-list.without-ripple(mat.$private-mdc-theme-styles-query);

    .mat-mdc-select-value {
      color: map.get($default, color);
    }

    .mat-mdc-select-placeholder {
      color: map.get($default, label);
    }

    .mat-mdc-select-disabled .mat-mdc-select-value {
      .mat-mdc-select-placeholder,
      & {
        color: $disabled-color;
      }
    }

    .mat-mdc-select-arrow {
      color: map.get($default, color);
    }

    .mat-mdc-form-field {
      &.mat-focused {
        &.mat-primary .mat-mdc-select-arrow {
          color: map.get($default, color);
        }

        &.mat-accent .mat-mdc-select-arrow {
          color: map.get($default, color);
        }

        &.mat-warn .mat-mdc-select-arrow {
          color: map.get($warn, color);
        }
      }

      .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
        color: map.get($warn, color);
      }

      .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
        color: $disabled-color;
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-menu-surface.core-styles(mat.$private-mdc-typography-styles-query);

    .mat-mdc-select-panel {
      @include mdc-list.list-base(mat.$private-mdc-typography-styles-query);

      border-radius: 4px !important;
      padding: 0 !important;
    }

    .mat-mdc-select {
      @include mdc-typography.typography(body2, $query: mat.$private-mdc-typography-styles-query);
    }
  }
}

@mixin density($config-or-theme) {
  // extends density
}

@mixin ftd-select($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-select') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
