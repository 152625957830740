@use 'core/palette';
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  p {
    font-weight: var(--mdc-typography-body1-font-weight, 400);
  }
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-icon {
  margin-right: 8px;
}

.main-container-fullscreen {
  background-color: palette.$ftd-bg-dark;
  color: palette.$ftd-white;
  display: flex;
  flex-direction: column;
  height: calc(100% + 17px);
  padding: 24px 32px;

  .mat-drawer-container {
    height: 100%;
  }
  #matrix-view-container {
    height: 80%;
  }
  .mat-drawer-content {
    overflow: hidden;
    mat-card#main-container-content {
      .mat-mdc-card-content {
        padding-bottom: 0;
        app-matrix-view-data {
          app-matrix-view {
            div#matrix-view-container {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
