@use 'core/palette';

app-markets-page {
  .mat-mdc-form-field {
    color: palette.$ftd-black;
  }

  ftd-tab-group {
    .mat-mdc-tab-label-container {
      flex-grow: 0;
    }
  }
}
