// stylelint-disable selector-class-pattern --
// Selector '.mdc-*' should only be used in this project.

@use 'sass:meta';
@use '@material/rtl/rtl';
@use '@material/theme/theme-color';
@use '@material/theme/theme';
@use '@material/feature-targeting/feature-targeting';
@use 'mdc/animation/animation';
@use '@material/typography/typography';
@use './variables';

@mixin core-styles($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);
  $feat-animation: feature-targeting.create-target($query, animation);

  // postcss-bem-linter: define floating-label
  .mdc-floating-label {
    @include typography.typography(subtitle1, $exclude-props: (line-height), $query: $query);

    @include feature-targeting.targets($feat-structure) {
      cursor: text;
      left: 0;
      line-height: 1.15rem;
      overflow: hidden;
      position: absolute;
      text-align: left;
      text-overflow: ellipsis;
      transform-origin: left top;
      white-space: nowrap;

      /* @alternate */
      // Force the label into its own layer to prevent visible layer promotion adjustments
      // when the ripple is activated behind it.
      will-change: transform;

      @include rtl.ignore-next-line;
      @include rtl.ignore-next-line;
      @include rtl.ignore-next-line;

      @include rtl.rtl {
        @include rtl.ignore-next-line;

        left: auto;
        right: 0;
        text-align: right;
        transform-origin: right top;

        @include rtl.ignore-next-line;
        @include rtl.ignore-next-line;
        @include rtl.ignore-next-line;
        @include rtl.ignore-next-line;
      }
    }

    @include feature-targeting.targets($feat-animation) {
      transition: transform variables.$transition-duration animation.$standard-curve-timing-function,
        color variables.$transition-duration animation.$standard-curve-timing-function;
    }
  }

  .mdc-floating-label--float-above {
    @include feature-targeting.targets($feat-structure) {
      cursor: auto;
    }
  }

  .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker) {
    &::after {
      @include _required-content($query);
    }

    @include rtl.rtl {
      &::after {
        @include _required-content-rtl($query);
      }
    }
  }

  @at-root {
    @include float-position(variables.$position-y, $query: $query);
    @include shake-animation(standard, $query: $query);
  }

  @include shake-keyframes(standard, variables.$position-y, $query: $query);
}

@mixin ink-color($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  @include feature-targeting.targets($feat-color) {
    @include theme.property(color, $color);
  }
}

// Used for textarea in case of scrolling
@mixin fill-color($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  @include feature-targeting.targets($feat-color) {
    @include theme.property(background-color, $color);
  }
}

@mixin shake-keyframes(
  $modifier,
  $positionY,
  $positionX: 0%,
  $scale: variables.$float-scale,
  $query: feature-targeting.all()
) {
  $feat-animation: feature-targeting.create-target($query, animation);

  @include feature-targeting.targets($feat-animation) {
    @keyframes mdc-floating-label-shake-float-above-#{$modifier} {
      0% {
        @include rtl.ignore-next-line;

        transform: translateX(calc(0 - #{$positionX})) translateY(-#{$positionY}) scale(#{$scale});
      }

      33% {
        animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
        transform: translateX(calc(4% - #{$positionX})) translateY(-#{$positionY}) scale(#{$scale});

        @include rtl.ignore-next-line;
      }

      66% {
        animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
        transform: translateX(calc(-4% - #{$positionX})) translateY(-#{$positionY}) scale(#{$scale});

        @include rtl.ignore-next-line;
      }

      100% {
        @include rtl.ignore-next-line;

        transform: translateX(calc(0 - #{$positionX})) translateY(-#{$positionY}) scale(#{$scale});
      }
    }
  }
}

@mixin float-position($positionY, $positionX: 0%, $scale: variables.$float-scale, $query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);
  $translateY: if(meta.type-of($positionY) == 'calculation', calc(-1 * $positionY), -1 * $positionY);

  .mdc-floating-label--float-above {
    @include feature-targeting.targets($feat-structure) {
      @if $positionX > 0 or $positionX < 0 {
        @include rtl.ignore-next-line;

        transform: translateY($translateY) translateX(-1 * $positionX) scale($scale);

        @include rtl.rtl {
          @include rtl.ignore-next-line;

          transform: translateY($translateY) translateX($positionX) scale($scale);
        }
      } @else {
        transform: translateY($translateY) scale($scale);
      }
    }
  }
}

@mixin shake-animation($modifier, $query: feature-targeting.all()) {
  $feat-animation: feature-targeting.create-target($query, animation);

  .mdc-floating-label--shake {
    @include feature-targeting.targets($feat-animation) {
      animation: mdc-floating-label-shake-float-above-#{$modifier} 250ms 1;
    }
  }
}

@mixin max-width($max-width, $query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    max-width: $max-width;
  }
}

///
/// Sets the CSS transition for the floating animation.
///
/// @param {Number} $duration-ms - Duration (in ms) of the animation.
/// @param {String} $timing-function - Optionally overrides the default animation timing function.
///
@mixin float-transition(
  $duration-ms,
  $timing-function: animation.$standard-curve-timing-function,
  $query: feature-targeting.all()
) {
  $feat-animation: feature-targeting.create-target($query, animation);

  @include feature-targeting.targets($feat-animation) {
    transition: color $duration-ms $timing-function, transform $duration-ms $timing-function;
  }
}

@mixin _required-content($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    @include rtl.ignore-next-line;

    content: '*';
    margin-left: 1px;
    margin-right: 0;

    @include rtl.ignore-next-line;
  }
}

// Need to specify LTR/RTL manually since rtl mixins will add ::after[dir=rtl]
// selector and that breaks some browsers
@mixin _required-content-rtl($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    @include rtl.ignore-next-line;

    margin-left: 0;
    margin-right: 1px;

    @include rtl.ignore-next-line;
  }
}
