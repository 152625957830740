@use 'sass:map';
@use 'sass:meta';
@use '@angular/material' as mat;

// Colors for the ripple elements.
@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $foreground-base: map.get($foreground, base);
  $color-opacity: 0.1;

  .mat-ripple-element {
    // If the ripple color is resolves to a color *type*, we can use it directly, otherwise
    // (e.g. it resolves to a CSS variable) we fall back to using the color and setting an opacity.
    @if meta.type-of($foreground-base) == color {
      background-color: rgba($foreground-base, $color-opacity);
    } @else {
      background-color: $foreground-base;
      opacity: $color-opacity;
    }
  }
}

@mixin ftd-ripple($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-ripple') {
    $color: mat.get-color-config($theme);

    @if $color != null {
      @include color($color);
    }
  }
}
