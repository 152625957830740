@use 'core/palette';

@mixin private-ftd-custom-scroll($border-color, $scrollbar-background: $border-color, $corner-background: transparent) {
  &::-webkit-scrollbar-thumb {
    border-color: $border-color;
  }

  &::-webkit-scrollbar-corner {
    background: $corner-background;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-background;
  }
}

@mixin scrollbars {
  ::-webkit-scrollbar {
    background: transparent;
    height: 12px;
    width: 12px;
  }

  ::-webkit-scrollbar-thumb:vertical:hover {
    border: 1px;
    width: 16px;
  }

  ::-webkit-scrollbar-thumb:horizontal:hover {
    border: 20px;
    height: 20px;
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #7b8ec0 0%, #565b76 100%);
    border: 2px solid palette.$ftd-bg-dark;
    border-radius: 100vw;
  }

  ::-webkit-scrollbar-track {
    background: palette.$ftd-bg-dark;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: linear-gradient(to right, #7b8ec0 0%, #565b76 100%);
    border: 2px solid palette.$ftd-bg-light;
  }

  ::-webkit-scrollbar-corner {
    background: palette.$ftd-bg-dark;
  }

  /* Special workaround for scrolls within the body element */
  body {
    ::-webkit-scrollbar-thumb {
      border-color: palette.$ftd-bg-light;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: palette.$ftd-bg-light;
    }
  }
}
