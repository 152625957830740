@use '@angular/material' as mat;
@use 'core/palette';
@use 'core/typography' as ftd-typography;

mat-mdc-tooltip-component {
  .mat-tooltip {
    align-items: center;
    background-color: palette.$ftd-bg-elevation;
    border-radius: 4px;
    box-shadow: 0 0 6px fade-in(palette.$ftd-black, 0.25);
    color: palette.$ftd-white;
    display: flex;
    flex-direction: row;
    font-family: mat.font-family(ftd-typography.$ftd-typography, 'body-2');
    font-size: 1rem;
    font-style: normal;
    font-weight: mat.font-weight(ftd-typography.$ftd-typography, 'body-2');
    gap: 8px;
    justify-content: center;
    line-height: 24px;
    padding: 4px 8px;
  }
}

.mde-popover-content {
  align-items: center;
  background-color: palette.$ftd-bg-elevation;
  border-radius: 4px;
  box-shadow: 0 0 6px fade-in(palette.$ftd-black, 0.25);
  color: palette.$ftd-white;
  display: flex;
  flex-direction: column;
  font-family: mat.font-family(ftd-typography.$ftd-typography, 'body-2');
  font-size: 1rem;
  font-style: normal;
  font-weight: mat.font-weight(ftd-typography.$ftd-typography, 'body-2');
  gap: 8px;
  justify-content: center;
  line-height: 24px;
  padding: 4px 8px;
}
