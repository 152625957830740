@use 'core/palette';

.cdk-overlay-container {
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 400px;

    .price-range-select-slider-container {
      .price-range-inputs-container {
        app-ftd-input {
          .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
            text-align: right;
          }

          input {
            margin-left: -10px;
          }

          .mat-mdc-form-field-icon-prefix {
            padding: 0 4px 0 10px;
          }
        }
      }

      mat-slider {
        width: 235px;

        mat-slider-visual-thumb {
          .mdc-slider__thumb-knob {
            background-color: palette.$ftd-primary;
            border-color: palette.$ftd-primary;
          }
        }

        .mdc-slider__thumb--focused {
          .mdc-slider__thumb-knob {
            background-color: palette.$ftd-primary;
            border-color: palette.$ftd-primary;
          }
        }

        .mat-mdc-slider {
          margin-left: 0;
          margin-right: 0;
        }

        .mdc-slider__track {
          width: 245px;

          .mdc-slider__track--active_fill {
            border-color: palette.$ftd-primary;
          }

          .mdc-slider__track--inactive {
            background-color: palette.$ftd-primary;
          }

          .mdc-slider__track--inactive::before {
            background-color: palette.$ftd-primary;
          }
        }
      }
    }
  }
}
