@use 'sass:math';
@use 'sass:color';
@use 'sass:map';
@use '@material/density/variables' as density-variables;
@use 'mdc/floating-label/variables' as floating-label-variables;
@use 'mdc/notched-outline/variables' as notched-outline-variables;
@use '@material/theme/custom-properties';
@use '@material/theme/theme-color';
@use 'core/palette' as ftd-colors;

///
/// Returns outlined text field floating label position for given height.
///
/// @todo Instead of adjusting `$positionY` with label box height that might change based on floating label font size
///     wrap label in a child element to apply `transitionY(-50%)` to automatically offset based on box height.
///
@function get-outlined-label-position-y($text-field-height) {
  $offset: math.div(notched-outline-variables.$label-box-height, 2);

  // If $text-field-height is not a primitive value, but a custom property or a custom property map,
  // we cannot compute the label Y position at compilation time.
  // We will have to defer it to the browser, using calc().
  @if custom-properties.is-custom-prop($text-field-height) {
    $text-field-height: custom-properties.get-declaration-value($text-field-height);

    @return calc($offset + $text-field-height / 2); // Evaluated in the browser.
  }

  @if custom-properties.is-custom-prop-string($text-field-height) {
    @return calc($offset + $text-field-height / 2); // Evaluated in the browser.
  }

  @return $offset + math.div($text-field-height, 2); // Evaluated statically.
}

$base: map.get(ftd-colors.$ftd-color-map, ftd);
$default: map.get(ftd-colors.$ftd-color-map, default);
$accent: map.get(ftd-colors.$ftd-color-map, accent);
$warn: map.get(ftd-colors.$ftd-color-map, warn);
$ftd-disabled: map.get(ftd-colors.$ftd-color-map, disabled);
$error: error !default;
$disabled-border: $ftd-disabled !default;
$disabled-icon: $ftd-disabled !default;
$bottom-line-hover: map.get($default, color) !default;
$bottom-line-idle: map.get($default, color) !default;
$label: map.get($default, label) !default;
$ink-color: map.get($default, color) !default;
$helper-text-color: map.get($default, color) !default;
$icon-color: map.get($default, color) !default;
$focused-label-color: map.get($default, label) !default;
$placeholder-ink-color: ftd-colors.$ftd-grey-500 !default;
$affix-color: map.get($default, label) !default;
$disabled-label-color: $ftd-disabled !default;
$disabled-ink-color: $ftd-disabled !default;
$disabled-placeholder-ink-color: $ftd-disabled !default;
$disabled-helper-text-color: $ftd-disabled !default;
$disabled-affix-color: $ftd-disabled !default;
$background: color.mix(theme-color.prop-value(on-surface), theme-color.prop-value(surface), 4%) !default;
$disabled-background: color.mix(theme-color.prop-value(on-surface), theme-color.prop-value(surface), 2%) !default;
$secondary-text: map.get($default, color) !default;
$outlined-idle-border: map.get($default, color) !default;
$outlined-disabled-border: $ftd-disabled !default;
$outlined-hover-border: map.get($accent, color) !default;
$textarea-border: map.get($default, color) !default;
$textarea-background: map.get($default, color) !default;
$textarea-disabled-border-color: $ftd-disabled !default;

// cannot be transparent because multiline textarea input
// will make text unreadable
$textarea-disabled-background: $ftd-disabled !default;
$ripple-target: '.mdc-text-field__ripple';
$outlined-stroke-width: 2px !default;
$height: 40px !default;
$minimum-height: 36px !default;
$minimum-height-for-filled-label: 40px !default;
$maximum-height: $height !default;
$padding-horizontal: 16px !default;
$density-scale: default !default;
$density-config: (
  height: (
    default: $height,
    maximum: $maximum-height,
    minimum: $minimum-height,
  ),
) !default;
$shape-radius: small !default;
$label-position-y: floating-label-variables.$position-y !default;
$label-offset: 16px !default;

// TODO(b/154350788): Remove this variable, it's not used internally but it is
// externally in Angular
/// @deprecated this variable will be removed in the future.
/// Use get-outlined-label-position-y($height) instead
$outlined-label-position-y: get-outlined-label-position-y($height) !default;
$outlined-with-leading-icon-label-position-x: 32px !default;
$textarea-outlined-label-position-y: 24.75px !default; // visually ~4dp from top to baseline
$textarea-filled-label-position-y: 10.25px !default; // visually ~20dp from top to label baseline
$helper-line-padding: 10px !default;
$filled-baseline-top: 40px !default;
$input-height: 28px !default;
$textarea-label-top: 19px !default; // visually ~32dp from top to label baseline
$textarea-outlined-label-top: $textarea-label-top - notched-outline-variables.$border-width !default;
$textarea-line-height: 1.5rem !default; // 24dp from baseline to baseline
$textarea-input-handle-margin: 1px !default;

// Outlined textarea's first line should be placed at the same position as
// outlined textfield, and should look identical if it is 1 row. Since textfield
// is centered and font metrics vary for where the baseline is, the best way to
// ensure textarea and textfield align is with padding. At 56px height with a
// 24px line-height, a centered textfield has 16px of top and bottom padding.
// Textarea should use this to position itself.
$textarea-outlined-input-margin-top: 10px !default;
$textarea-outlined-input-margin-bottom: 10px !default;
$textarea-outlined-density-config: (
  margin-top: (
    default: $textarea-outlined-input-margin-top,
    maximum: $textarea-outlined-input-margin-top,
    minimum: $textarea-outlined-input-margin-top - 8,
  ),
  margin-bottom: (
    default: $textarea-outlined-input-margin-bottom,
    maximum: $textarea-outlined-input-margin-bottom,
    minimum: $textarea-outlined-input-margin-bottom - 8,
  ),
) !default; // remove 1/2 of scale to -4 for minimum
$textarea-outlined-label-density-config: (
  top: (
    default: $textarea-outlined-label-top,
    maximum: $textarea-outlined-label-top,
    minimum: $textarea-outlined-label-top - 8,
  ),
) !default; // remove 1/2 of scale to -4 for minimum
$textarea-filled-input-margin-top: 23px !default; // visually ~40dp from top to baseline
$textarea-filled-input-margin-bottom: 9px !default; // visually ~16dp from baseline to bottom
$textarea-filled-density-config: (
  margin-bottom: (
    default: $textarea-filled-input-margin-bottom,
    maximum: $textarea-filled-input-margin-bottom,
    minimum: $textarea-filled-input-margin-bottom - 4,
  ),
) !default; // scale to -1 for minimum
$textarea-filled-label-density-config: (
  top: (
    default: $textarea-label-top,
    maximum: $textarea-label-top,
    minimum: $textarea-label-top - 2,
  ),
) !default; // remove 1/2 of scale to -1 for minimm
$textarea-filled-no-label-input-margin-top: 10px !default; // see above explanation for outlined textarea margin
$textarea-filled-no-label-input-margin-bottom: 10px !default; // see above explanation for outlined textarea margin
$textarea-filled-no-label-density-config: (
  margin-top: (
    default: $textarea-filled-no-label-input-margin-top,
    maximum: $textarea-filled-no-label-input-margin-top,
    minimum: $textarea-filled-no-label-input-margin-top - 8,
  ),
  margin-bottom: (
    default: $textarea-filled-no-label-input-margin-bottom,
    maximum: $textarea-filled-no-label-input-margin-bottom,
    minimum: $textarea-filled-no-label-input-margin-bottom - 8,
  ),
) !default; // remove 1/2 of scale to -4 for minimum
$textarea-internal-counter-input-margin-bottom: 2px !default; // visually ~20dp from baseline to counter baseline
$textarea-internal-counter-baseline-bottom: 16px !default;

// Note that the scale factor is an eyeballed approximation of what's shown in the mocks.

$prefix-padding: 2px !default;
$prefix-end-aligned-padding: 12px !default;
$suffix-padding: 12px !default;
$suffix-end-aligned-padding: 2px !default;
