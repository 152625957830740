@use 'sass:map';

@mixin private-apply-button-status-colors($button-status) {
  @each $status, $colors in $button-status {
    @if $status == base {
      background: map.get($colors, background);
      border: 1px solid map.get($colors, border-color);
      color: map.get($colors, color);
    } @else {
      &:#{$status} {
        background: map.get($colors, background);
        border: 1px solid map.get($colors, border-color);
        color: map.get($colors, color);
      }
    }
  }
}

@mixin private-apply-button-colors($button-colors) {
  @each $button-type, $button-status in $button-colors {
    @each $type in $button-type {
      &-#{$type} {
        @include private-apply-button-status-colors($button-status);
      }
    }
  }
}
