@font-face {
  font-display: swap;
  font-family: BMWGroupTNCondensedPro-Bold;
  font-style: normal;
  font-weight: bold;
  src: url('./assets/BMWGroupTNCondensedPro-Bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: BMWGroupTNCondensedPro-Regular;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/BMWGroupTNCondensedPro-Regular.woff2') format('woff2');
}

/* BMW Group TN Condensed Pro @font-face */

@font-face {
  font-display: swap;
  font-family: 'BMW Group TN Condensed Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/BMWGroupTNCondensedPro-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'BMW Group TN Condensed Pro';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/BMWGroupTNCondensedPro-Bold.woff2') format('woff2');
}

$ftd-font-condensed-regular: 'BMWGroupTNCondensedPro-Regular', serif;
$ftd-font-condensed-bold: 'BMWGroupTNCondensedPro-Bold', serif;
