@use '@angular/material' as mat;
@use '@material/radio/radio' as mdc-radio;
@use '@material/radio/radio-theme' as mdc-radio-theme;
@use 'mdc/form-field/mixins' as mdc-form-field;
@use './radio-private';
@use 'sass:map';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: mat.get-color-from-palette(map.get($config, primary));
  $accent: mat.get-color-from-palette(map.get($config, accent));
  $warn: mat.get-color-from-palette(map.get($config, warn));

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-radio-button {
      @include mdc-form-field.core-styles($query: mat.$private-mdc-theme-styles-query);

      &.mat-primary {
        @include radio-private.private-radio-color($config, $primary);
      }

      &.mat-accent {
        @include radio-private.private-radio-color($config, $accent);
      }

      &.mat-warn {
        @include radio-private.private-radio-color($config, $warn);
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-radio.without-ripple($query: mat.$private-mdc-typography-styles-query);
    @include mdc-form-field.core-styles($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  .mat-mdc-radio-button .mdc-radio {
    @include mdc-radio-theme.density($density-scale, $query: mat.$private-mdc-base-styles-query);
  }

  @include mat.private-if-touch-targets-unsupported($density-scale) {
    .mat-mdc-radio-touch-target {
      display: none;
    }
  }
}

@mixin ftd-radio($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-radio') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
