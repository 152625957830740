// stylelint-disable selector-class-pattern --
// Selector '.mdc-*' should only be used in this project.

@use '@material/feature-targeting/feature-targeting';
@use '@material/theme/theme';
@use '@material/theme/custom-properties';
@use 'mdc/shape/shape' as shape-mixins;
@use 'mdc/animation/animation' as animation-variables;
@use '@material/elevation/mixins' as elevation-mixins;
@use '@material/rtl/rtl';
@use 'variables';
@use 'core/palette' as ftd-colors;
@use 'helpers/scrollbars';

//
// Public
//

@mixin core-styles($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  // postcss-bem-linter: define menu-surface
  .mdc-menu-surface {
    @include base_($query);
    @include elevation-mixins.elevation($z-value: 8, $query: $query);
    @include fill-color(ftd-colors.$ftd-bg-elevation, $query);
    @include ink-color(ftd-colors.$ftd-white, $query);
    @include shape-radius(variables.$shape-radius, false, $query);

    @include feature-targeting.targets($feat-structure) {
      @include rtl.reflexive-property(transform-origin, top left, top right);
    }

    @include scrollbars.private-ftd-custom-scroll(ftd-colors.$ftd-bg-elevation);
  }

  .mdc-menu-surface--anchor {
    @include feature-targeting.targets($feat-structure) {
      overflow: visible;
      position: relative;
    }
  }

  .mdc-menu-surface--fixed {
    @include feature-targeting.targets($feat-structure) {
      position: fixed;
    }
  }

  .mdc-menu-surface--fullwidth {
    @include feature-targeting.targets($feat-structure) {
      width: 100%;
    }
  }

  // postcss-bem-linter: end
}

@mixin ink-color($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  @include feature-targeting.targets($feat-color) {
    @include theme.property(color, $color);
  }
}

@mixin fill-color($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  @include feature-targeting.targets($feat-color) {
    @include theme.property(background-color, $color);
  }
}

@mixin shape-radius($radius, $rtl-reflexive: false, $query: feature-targeting.all()) {
  @include shape-mixins.radius($radius, $rtl-reflexive, $query: $query);
}

// Used by filled variants of GM components to conditionally flatten the top
// corners of the menu.
@mixin flatten-top-when-opened-below($query: feature-targeting.all()) {
  .mdc-menu-surface--is-open-below {
    $feat-structure: feature-targeting.create-target($query, structure);

    @include feature-targeting.targets($feat-structure) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

//
// Private
//

@mixin base_($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);
  $feat-animation: feature-targeting.create-target($query, animation);

  @include feature-targeting.targets($feat-structure) {
    box-sizing: border-box;
    display: none;
    margin: 0;
    opacity: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    transform: scale(1);
    transform-origin: top left;
    will-change: transform, opacity;
    z-index: variables.$z-index;

    $max-width: custom-properties.create(--mdc-menu-max-width, calc(100vw - #{variables.$min-distance-from-edge}));

    @include theme.property(max-width, $max-width);

    $max-height: custom-properties.create(--mdc-menu-max-height, calc(100vh - #{variables.$min-distance-from-edge}));

    @include theme.property(max-height, $max-height);
  }

  @include feature-targeting.targets($feat-animation) {
    transition: opacity variables.$fade-in-duration linear,
      transform variables.$scale-duration animation-variables.$deceleration-curve-timing-function,
      height 250ms animation-variables.$deceleration-curve-timing-function;
  }

  &:focus {
    @include feature-targeting.targets($feat-structure) {
      outline: none;
    }
  }

  &--animating-open {
    @include feature-targeting.targets($feat-structure) {
      display: inline-block;
      opacity: 0;
      transform: scale(0.8);
    }
  }

  // Render this after `--animating-open` to override `opacity` & `transform`
  // CSS properties.
  &--open {
    @include feature-targeting.targets($feat-structure) {
      display: inline-block;
      opacity: 1;
      transform: scale(1);
    }
  }

  &--animating-closed {
    @include feature-targeting.targets($feat-structure) {
      display: inline-block;
      opacity: 0;
    }

    @include feature-targeting.targets($feat-animation) {
      transition: opacity variables.$fade-out-duration linear;
    }
  }
}
