@use '@angular/material' as mat;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/snackbar/snackbar-theme' as mdc-snackbar-theme;
@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use 'core/palette';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $accent: map.get($config, accent);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-snack-bar-container {
      --mat-mdc-snack-bar-button-color: palette.$ftd-white;

      @include mdc-snackbar-theme.theme(
        (
          container-color: palette.$ftd-bg-elevation,
          supporting-text-color: palette.$ftd-white,
        )
      );
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @if $config {
      .mat-mdc-snack-bar-container {
        @include mdc-snackbar-theme.theme(
          (
            supporting-text-font: mat.font-family($config, body-2),
            supporting-text-line-height: mat.line-height($config, body-2),
            supporting-text-size: mat.font-size($config, body-2),
            supporting-text-weight: mat.font-weight($config, body-2),
          )
        );
      }
    }
  }
}

@mixin density($config-or-theme) {
  // extends density
}

@mixin ftd-snack-bar($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-snack-bar') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
