@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables' as expansion-variables;
@use 'mixins' as expansion-mixins;
@use 'mdc/core/density/compatibility';
@use 'core/palette';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-expansion-panel {
    // @include mat.overridable-elevation(2, palette.$ftd-black);

    background: transparent;
    border-bottom: 1px solid palette.$ftd-alternative;
    border-top: 1px solid palette.$ftd-alternative;
    color: palette.$ftd-white;
    margin: 8px;
  }

  #modal-accordion {
    .mat-expansion-panel {
      background: transparent;
      border-bottom: 1px solid palette.$ftd-alternative;
      border-top: 0;
      color: palette.$ftd-white;
      margin: 0px;

      .mat-expansion-panel-body {
        padding: 0;
      }

      .mat-expansion-panel-header {
        height: 40px;
      }

      .mdc-data-table__header-row {
        height: 40px;
      }

      .mdc-data-table__cell {
        padding: 0 8px;

        &.no-padding {
          padding: 0;
        }
      }
      .mdc-data-table__header-cell {
        border-top: 1px solid palette.$ftd-alternative;
        padding: 0 8px;
      }
    }
  }

  .mat-action-row {
    border-top-color: mat.get-color-from-palette($foreground, divider);
  }

  @include expansion-mixins.private-expansion-focus {
    background: mat.get-color-from-palette($background, hover);
  }

  // Disable the hover on touch devices since it can appear like it is stuck. We can't use
  // `@media (hover)` above, because the desktop support browser support isn't great.
  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
      background: mat.get-color-from-palette($background, card);
    }
  }

  .mat-expansion-panel-header-title {
    color: palette.$ftd-white;
  }

  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: palette.$ftd-white;
    font-weight: 400;
    margin: 0;
  }

  .mat-expansion-panel-header[aria-disabled='true'] {
    color: mat.get-color-from-palette($foreground, disabled-button);

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      color: inherit;
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2014-config(mat.get-typography-config($config-or-theme));

  .mat-expansion-panel-header {
    font: {
      family: mat.font-family($config, subheading-1);
      size: mat.font-size($config, subheading-1);
      weight: mat.font-weight($config, subheading-1);
    }
  }

  .mat-expansion-panel-content {
    @include mat.typography-level($config, body-1);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);
  $expanded-height: compatibility.private-density-prop-value(
    expansion-variables.$header-density-config,
    $density-scale,
    expanded-height
  );
  $collapsed-height: compatibility.private-density-prop-value(
    expansion-variables.$header-density-config,
    $density-scale,
    collapsed-height
  );

  @include compatibility.private-density-legacy-compatibility {
    .mat-expansion-panel-header {
      height: $collapsed-height;
      padding: 0 8px;

      &.mat-expanded {
        height: $expanded-height;
      }
    }
  }
}

@mixin ftd-expansion($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-expansion') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }

    .mat-accordion .mat-expansion-panel:first-of-type,
    .mat-accordion .mat-expansion-panel:last-of-type {
      border-radius: 0;
    }

    .mat-expansion-panel-body {
      padding: 0 8px;
    }
  }
}
