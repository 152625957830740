@use 'core/palette';

.isTableDropdown {
  &.mat-mdc-form-field {
    background-color: white;

    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
      border-radius: 0 !important;
    }
    .mat-mdc-select-arrow-wrapper {
      left: 10px;
      position: relative;
      top: 2px;

      .mat-mdc-form-field.mat-focused.mat-primary {
        &.mat-mdc-select-arrow {
          color: palette.$ftd-black;
        }
      }
    }

    .mat-mdc-select-arrow {
      color: palette.$ftd-black;
    }

    .mat-mdc-select-value-text {
      color: palette.$ftd-grey-700;
      left: 0;
      position: relative;
    }
  }
}
