@use '@angular/material' as mat;
@use 'core';
@use 'customs';
@use 'mdc';

@mixin apply-ftd-theme($theme) {
  @include core.ftd-base($theme);

  .ftd-theme {
    --mat-select-panel-background-color: #282f47;

    @include mat.private-check-duplicate-theme-styles($theme, 'angular-material-theme') {
      @include mdc.ftd-core($theme);
      @include mdc.ftd-checkbox($theme);
      @include mdc.ftd-form-field($theme);
      @include mdc.ftd-select($theme);
      @include mdc.ftd-slide-toggle($theme);
      @include mdc.ftd-list($theme);
      @include mdc.ftd-spinner($theme);
      @include mdc.ftd-table($theme);
      @include mdc.ftd-chips($theme);
      @include mdc.ftd-dialog($theme);
      @include mdc.ftd-progress-bar($theme);
      @include mdc.ftd-date-picker($theme);
      @include mdc.ftd-snack-bar($theme);
      @include mdc.ftd-divider($theme);
      @include mdc.ftd-radio($theme);
      @include mdc.ftd-autocomplete($theme);
      @include mdc.ftd-badge($theme);
      @include mdc.ftd-button($theme);
      @include mdc.ftd-button-fab($theme);
      @include mdc.ftd-button-icon($theme);
      @include mdc.ftd-menu($theme);
      @include mdc.ftd-expansion($theme);
      @include mdc.ftd-tooltip($theme);
      @include mdc.ftd-tabs($theme);
      @include customs.customs-apply($theme);
    }
  }
}
