@use '@angular/material' as mat;
@use '@material/radio/radio-theme' as mdc-radio-theme;
@use '@material/theme/theme-color' as mdc-theme-color;
@use 'sass:map';
@use 'core/palette' as ftd-colors;

$private-radio-theme-config: map.merge(
  mdc-radio-theme.$light-theme,
  (
    // Exclude the styles we don't need.
    selected-focus-state-layer-color: null,
    selected-focus-state-layer-opacity: null,
    selected-hover-state-layer-color: null,
    selected-hover-state-layer-opacity: null,
    selected-pressed-state-layer-color: null,
    selected-pressed-state-layer-opacity: null,
    unselected-focus-icon-color: null,
    unselected-focus-state-layer-color: null,
    unselected-focus-state-layer-opacity: null,
    unselected-hover-state-layer-color: null,
    unselected-hover-state-layer-opacity: null,
    unselected-pressed-state-layer-color: null,
    unselected-pressed-state-layer-opacity: null
  )
);

@mixin private-radio-color($color-config, $color-palette) {
  $foreground: map.get($color-config, foreground);
  $on-surface: ftd-colors.$ftd-white;
  $is-dark: map.get($color-config, is-dark);
  $active-border-color: if($is-dark, ftd-colors.$ftd-white, ftd-colors.$ftd-white);

  & {
    @include mdc-radio-theme.theme(
      (
        // The disabled colors don't use the `rgba` version, because
        // MDC applies a separate opacity to disabled buttons.
        disabled-selected-icon-color: mdc-theme-color.$on-surface,
        disabled-unselected-icon-color: mdc-theme-color.$on-surface,
        unselected-focus-icon-color: $active-border-color,
        unselected-hover-icon-color: $active-border-color,
        unselected-icon-color: $on-surface,
        unselected-pressed-icon-color: $on-surface,
        selected-focus-icon-color: $color-palette,
        selected-hover-icon-color: $color-palette,
        selected-icon-color: $color-palette,
        selected-pressed-icon-color: $color-palette
      )
    );

    --mat-mdc-radio-ripple-color: #{mdc-theme-color.prop-value(on-surface)};

    // MDC should set the disabled color on the label, but doesn't, so we do it here instead.
    .mdc-radio--disabled + label {
      color: mat.get-color-from-palette($foreground, disabled-text);
    }

    --mat-mdc-radio-checked-ripple-color: #{$color-palette};
  }
}
