@use '@angular/material' as mat;
@use 'core/palette';
@use 'core/typography' as ftd-typography;

mat-card.mdc-card {
  background-color: palette.$ftd-bg-light;
  border-radius: 0;

  @include mat.typography-level(ftd-typography.$ftd-typography, 'body-2');
}
