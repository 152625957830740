@use 'sass:map';
@use '@material/icon-button/mixins' as mdc-icon-button;
@use '@material/icon-button/icon-button-theme' as mdc-icon-button-theme;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@angular/material' as mat;
@use 'private' as button-theme-private;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    $is-dark: map.get($config, is-dark);
    $on-surface: mdc-theme-color.prop-value(on-surface);
    $disabled-color: rgba($on-surface, if($is-dark, 0.5, 0.38));

    .mat-mdc-icon-button {
      @include button-theme-private.ripple-theme-styles($config, false);

      &.mat-primary {
        @include mdc-icon-button-theme.theme(
          (
            icon-color: mdc-theme-color.prop-value(primary),
          )
        );
      }

      &.mat-accent {
        @include mdc-icon-button-theme.theme(
          (
            icon-color: mdc-theme-color.prop-value(secondary),
          )
        );
      }

      &.mat-warn {
        @include mdc-icon-button-theme.theme(
          (
            icon-color: (
              mdc-theme-color.prop-value(error),
            ),
          )
        );
      }

      @include button-theme-private.apply-disabled-style {
        @include mdc-icon-button-theme.theme(
          (
            icon-color: $disabled-color,
            disabled-icon-color: $disabled-color,
          )
        );
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-icon-button.without-ripple($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  // Use `mat-mdc-button-base` to increase the specificity over the button's structural styles.
  .mat-mdc-icon-button.mat-mdc-button-base {
    @include mdc-icon-button.density($density-scale, $query: mat.$private-mdc-base-styles-query);
    @include button-theme-private.touch-target-density($density-scale);
  }
}

@mixin ftd-button-icon($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-icon-button') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
