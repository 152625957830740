@use '@angular/material' as mat;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/linear-progress/linear-progress-theme' as mdc-linear-progress-theme;
@use 'core/palette';
@use 'sass:color';
@use 'sass:meta';

@mixin _palette-styles($color, $track-color) {
  @include mdc-linear-progress-theme.theme-styles(
    (
      track-color: $track-color,
    )
  );
  @include mdc-linear-progress-theme.theme(
    (
      active-indicator-color: $color,
    )
  );
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-progress-bar {
      @include _palette-styles(palette.$ftd-info-200, palette.$ftd-focus);

      &.mat-accent {
        @include _palette-styles(palette.$ftd-info-200, palette.$ftd-focus);
      }

      &.mat-warn {
        @include _palette-styles(palette.$ftd-risk-100, palette.$ftd-negative);
      }
    }
  }
}

@mixin typography($config-or-theme) {
  // extends typography
}

@mixin density($config-or-theme) {
  // extends typography
}

@mixin ftd-progress-bar($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-progress-bar') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
