@use '@angular/material' as mat;
@use '@material/checkbox/checkbox' as mdc-checkbox;
@use '@material/checkbox/checkbox-theme' as mdc-checkbox-theme;
@use 'mdc/form-field/mixins' as mdc-form-field;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/typography/typography';
@use '@material/theme/theme';
@use '@material/feature-targeting/feature-targeting';
@use '@material/rtl/rtl';
@use 'checkbox-private';
@use 'sass:map';
@use 'sass:color';
@use 'core/palette' as colors;

// Apply ripple colors to the MatRipple element and the MDC ripple element when the
// checkbox is selected.
@mixin _selected-ripple-colors($theme, $mdc-color) {
  .mdc-checkbox--selected ~ {
    .mat-mdc-checkbox-ripple {
      .mat-ripple-element {
        background-color: colors.$ftd-white;
        opacity: 0;
      }
    }
  }

  .mdc-checkbox__ripple {
    background: $theme;
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: mat.get-color-from-palette(map.get($config, primary));
  $accent: mat.get-color-from-palette(map.get($config, accent));
  $warn: mat.get-color-from-palette(map.get($config, warn));
  $foreground: map.get($config, foreground);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-checkbox {
      $query: mat.$private-mdc-theme-styles-query;
      $feat-color: feature-targeting.create-target($query, color);
      $feat-structure: feature-targeting.create-target($query, structure);

      .mdc-form-field {
        @include typography.typography(body2, $query);

        @include feature-targeting.targets($feat-color) {
          @include theme.property(color, colors.$ftd-white);
        }

        @include feature-targeting.targets($feat-structure) {
          align-items: center;
          display: inline-flex;
          vertical-align: middle;

          &[hidden] {
            display: none;
          }
        }

        > label {
          @include feature-targeting.targets($feat-structure) {
            @include rtl.reflexive-property(margin, 0, auto);
            @include rtl.reflexive-property(padding, 4px, 0);

            order: 0;
          }
        }
      }

      .mdc-form-field--nowrap {
        > label {
          @include feature-targeting.targets($feat-structure) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .mdc-form-field--align-end {
        > label {
          @include feature-targeting.targets($feat-structure) {
            @include rtl.reflexive-property(margin, auto, 0);
            @include rtl.reflexive-property(padding, 0, 4px);

            order: -1;
          }
        }
      }

      .mdc-form-field--space-between {
        @include feature-targeting.targets($feat-structure) {
          justify-content: space-between;
        }

        > label {
          @include feature-targeting.targets($feat-structure) {
            margin: 0;

            @include rtl.rtl {
              // RTL needed for specificity
              margin: 0;
            }
          }
        }
      }

      .mdc-checkbox__ripple {
        background: transparent;
      }

      // MDC's checkbox doesn't support a `color` property. We add support for it by adding a CSS
      // class for accent and warn style, and applying the appropriate overrides below. Since we
      // don't use MDC's ripple, we also need to set the color for our replacement ripple.
      &.mat-primary {
        @include checkbox-private.private-checkbox-styles-with-color($config, $primary, primary);
        @include _selected-ripple-colors($primary, primary);
      }

      &.mat-accent {
        @include mdc-checkbox-theme.theme(
          (
            selected-checkmark-color: colors.$ftd-bg-dark,

            selected-focus-icon-color: colors.$ftd-white,
            selected-hover-icon-color: colors.$ftd-white,
            selected-icon-color: colors.$ftd-white,
            selected-pressed-icon-color: colors.$ftd-white,
            unselected-focus-icon-color: colors.$ftd-white,
            unselected-hover-icon-color: colors.$ftd-white,

            disabled-selected-icon-color: colors.$ftd-grey-500,
            disabled-unselected-icon-color: colors.$ftd-grey-500,

            unselected-icon-color: colors.$ftd-white,
            unselected-pressed-icon-color: colors.$ftd-white,
          )
        );
      }

      &.mat-warn {
        @include checkbox-private.private-checkbox-styles-with-color($config, $warn, error);
        @include _selected-ripple-colors($warn, error);
      }
    }

    .mat-mdc-checkbox-disabled label {
      // MDC should set the disabled color on the label, but doesn't, so we do it here instead.
      color: colors.$ftd-grey-500;
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-checkbox.without-ripple($query: mat.$private-mdc-typography-styles-query);
    @include mdc-form-field.core-styles($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  @include mat.private-disable-mdc-fallback-declarations {
    .mat-mdc-checkbox .mdc-checkbox {
      @include mdc-checkbox-theme.density(-2, $query: mat.$private-mdc-base-styles-query);
    }

    @include mat.private-if-touch-targets-unsupported($density-scale) {
      .mat-mdc-checkbox-touch-target {
        display: none;
      }
    }
  }
}

@mixin ftd-checkbox($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-checkbox') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
