// Flex-based table structure
$header-row-height: 40px;
$row-height: 40px;
$row-horizontal-padding: 24px;

// Only use tag name selectors here since the styles are shared between MDC and non-MDC
@mixin private-table-flex-styles {
  mat-table {
    display: block;
  }

  mat-header-row {
    min-height: $header-row-height;
  }

  mat-row,
  mat-footer-row {
    min-height: $row-height;
  }

  mat-row,
  mat-header-row,
  mat-footer-row {
    align-items: center;
    border-style: solid;

    // Define a border style, but then widths default to 3px. Reset them to 0px except the bottom
    // which should be 1px;
    border-width: 0;
    border-bottom-width: 1px;
    box-sizing: border-box;
    display: flex;
  }

  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    // Note: we use `first-of-type`/`last-of-type` here in order to prevent extra
    // elements like ripples or badges from throwing off the layout (see #11165).
    &:first-of-type {
      padding-left: $row-horizontal-padding;

      [dir='rtl'] &:not(:only-of-type) {
        padding-left: 0;
        padding-right: $row-horizontal-padding;
      }
    }

    &:last-of-type {
      padding-right: $row-horizontal-padding;

      [dir='rtl'] &:not(:only-of-type) {
        padding-left: $row-horizontal-padding;
        padding-right: 0;
      }
    }
  }

  mat-cell,
  mat-header-cell,
  mat-footer-cell {
    align-items: center;
    display: flex;
    flex: 1;
    min-height: inherit;
    overflow: hidden;
    word-wrap: break-word;
  }
}
