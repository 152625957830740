@font-face {
  font-display: swap;
  font-family: BMWGroupTNCondensedPro-Bold;
  font-style: normal;
  font-weight: bold;
  src: url("./assets/BMWGroupTNCondensedPro-Bold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: BMWGroupTNCondensedPro-Regular;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/BMWGroupTNCondensedPro-Regular.woff2") format("woff2");
}
/* BMW Group TN Condensed Pro @font-face */
@font-face {
  font-display: swap;
  font-family: "BMW Group TN Condensed Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/BMWGroupTNCondensedPro-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "BMW Group TN Condensed Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/BMWGroupTNCondensedPro-Bold.woff2") format("woff2");
}
.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.my-1 {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.my-3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.my-4 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.my-5 {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 3rem;
}

.mr-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 3rem;
}

.ml-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.py-1 {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.py-3 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.py-4 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.py-5 {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-5 {
  padding-right: 3rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-5 {
  padding-left: 3rem;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.min-px-100 {
  min-width: 100px;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

/** Helper function to convert pixel to rem units */
.slider-box .mat-slider.mat-accent .mat-slider-thumb {
  background-color: #ff8761;
}
.slider-box .mat-slider-horizontal .mat-slider-track-wrapper {
  width: 0;
}

.ftd-mat-menu .mat-menu-content {
  padding: 8px;
}

.backdrop-background {
  backdrop-filter: blur(2px);
  background: #fff 0.5;
}

.ftd-modal-primary .mat-dialog-container {
  background-color: #1e2339;
  color: #fff;
}
.ftd-modal-secondary .mat-dialog-container {
  background-color: #fff;
  color: #000;
}
.ftd-modal-tertiary .mat-dialog-container {
  background-color: #33354e;
  color: #1e2339;
}

#comments-container {
  max-height: 38vh;
  overflow: auto;
}
#comments-container .comments-container-item .own-user-comment {
  color: #f6c800;
}
#comments-container .comments-container-item .user-comment {
  color: #008ff0;
}

mat-mdc-tooltip-component .mat-tooltip {
  align-items: center;
  background-color: #282f47;
  border-radius: 4px;
  box-shadow: 0 0 6px black;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: "BMW Group TN Condensed Pro", serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  justify-content: center;
  line-height: 24px;
  padding: 4px 8px;
}

.mde-popover-content {
  align-items: center;
  background-color: #282f47;
  border-radius: 4px;
  box-shadow: 0 0 6px black;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "BMW Group TN Condensed Pro", serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  justify-content: center;
  line-height: 24px;
  padding: 4px 8px;
}

.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel.menu-position {
  position: relative;
  right: 30%;
}

div#mv-filter-container .price-editor-filled-toggle-label {
  margin-right: 8px;
}
div#mv-filter-container mat-form-field#ftd-dropdown-mv-filter-price-editor-filled {
  margin-bottom: 16px;
}
div#mv-filter-container mat-form-field#ftd-dropdown-mv-filter-planning-horizon {
  visibility: hidden;
}
div#mv-filter-container mat-form-field {
  margin-bottom: 8px;
  max-height: 40px;
  width: 160px;
}
div#mv-filter-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.filter-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.size {
  height: calc(100vh - 328px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 174px;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):not(:hover) .checkbox-bulk:not(.checked) {
  opacity: 0;
}

table#scenario-list-table.mat-mdc-table {
  background: #151829;
}
table#scenario-list-table.mat-mdc-table .mat-sort-header-arrow {
  color: #fff;
}
table#scenario-list-table.mat-mdc-table .mat-mdc-row .mat-mdc-cell,
table#scenario-list-table.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  border-bottom: 1px solid #565b76;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}
table#scenario-list-table.mat-mdc-table .mat-mdc-header-cell {
  background-color: #151829;
}
table#scenario-list-table.mat-mdc-table .mat-mdc-row,
table#scenario-list-table.mat-mdc-table .mat-mdc-header-row {
  background-color: #151829;
}
table#scenario-list-table.mat-mdc-table .mat-mdc-row:hover,
table#scenario-list-table.mat-mdc-table .mat-mdc-header-row:hover {
  background-color: #565b76;
}
table#scenario-list-table.mat-mdc-table .scenario-delete {
  display: flex;
  justify-content: flex-end;
}
table#scenario-list-table.mat-mdc-table .mat-mdc-row.scenario-selected {
  background-color: #565b76;
}
table#scenario-list-table.mat-mdc-table .mat-mdc-row.mat-no-data-row .mat-mdc-cell {
  border: none;
  cursor: default;
}

table#discount-drafts-list-table.mat-mdc-table {
  background: #151829;
}
table#discount-drafts-list-table.mat-mdc-table .mat-sort-header-arrow {
  color: #fff;
}
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-row .mat-mdc-cell,
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  border-bottom: 1px solid #565b76;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-header-cell {
  background-color: #151829;
}
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-row,
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-header-row {
  background-color: #151829;
}
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-row:hover,
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-header-row:hover {
  background-color: #565b76;
}
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-row.discount-selected {
  background-color: #565b76;
}
table#discount-drafts-list-table.mat-mdc-table .mat-mdc-row.mat-no-data-row .mat-mdc-cell {
  border: none;
  cursor: default;
}

.ftd-modal-header-icon-color.info {
  color: #008ff0;
}
.ftd-modal-header-icon-color.success {
  color: #078007;
}
.ftd-modal-header-icon-color.error {
  color: #d92424;
}
.ftd-modal-header-icon-color.warning {
  color: #f6c800;
}

div.ftd-tab-label-traffic-light {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
}
div.ftd-tab-label-traffic-light.small {
  height: 10px;
  width: 10px;
}
div.ftd-tab-label-traffic-light.overview {
  height: 14px;
  width: 14px;
}
div.ftd-tab-label-traffic-light mat-icon {
  width: 16px;
  height: 16px;
  padding: 0;
  font-size: 16px;
  color: #fff;
}
div.ftd-tab-label-traffic-light mat-icon svg,
div.ftd-tab-label-traffic-light mat-icon img {
  width: 16px;
  height: 16px;
}
div.ftd-tab-label-traffic-light mat-icon .mdc-icon-button__ripple::before, div.ftd-tab-label-traffic-light mat-icon .mdc-icon-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #fff);
}
div.ftd-tab-label-traffic-light mat-icon:hover .mdc-icon-button__ripple::before, div.ftd-tab-label-traffic-light mat-icon.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
div.ftd-tab-label-traffic-light mat-icon.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, div.ftd-tab-label-traffic-light mat-icon:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
div.ftd-tab-label-traffic-light mat-icon:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
div.ftd-tab-label-traffic-light mat-icon:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
div.ftd-tab-label-traffic-light mat-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
div.ftd-tab-label-traffic-light.green {
  background-color: #8cc59a;
}
div.ftd-tab-label-traffic-light.green mat-icon {
  color: #078007;
}
div.ftd-tab-label-traffic-light.red {
  background-color: #d47d7e;
}
div.ftd-tab-label-traffic-light.red mat-icon {
  color: #d92424;
}
div.ftd-tab-label-traffic-light.grey {
  background-color: #d6d6d6;
}
div.ftd-tab-label-traffic-light.grey mat-icon {
  color: #494949;
}
div.ftd-tab-label-traffic-light.trafficLightNotAvailable {
  background-color: #d6d6d6;
}
div.ftd-tab-label-traffic-light.trafficLightNotAvailable mat-icon {
  color: #494949;
}
div.ftd-tab-label-traffic-light.up mat-icon {
  transform: rotate(0deg);
}
div.ftd-tab-label-traffic-light.down mat-icon {
  transform: rotate(180deg);
}
div.ftd-tab-label-traffic-light.keep mat-icon {
  transform: rotate(90deg);
}

mat-card.mdc-card {
  background-color: #1e2339;
  border-radius: 0;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}

.isTableDropdown.mat-mdc-form-field {
  background-color: white;
}
.isTableDropdown.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 0 !important;
}
.isTableDropdown.mat-mdc-form-field .mat-mdc-select-arrow-wrapper {
  left: 10px;
  position: relative;
  top: 2px;
}
.isTableDropdown.mat-mdc-form-field .mat-mdc-select-arrow-wrapper .mat-mdc-form-field.mat-focused.mat-primary.mat-mdc-select-arrow {
  color: #000;
}
.isTableDropdown.mat-mdc-form-field .mat-mdc-select-arrow {
  color: #000;
}
.isTableDropdown.mat-mdc-form-field .mat-mdc-select-value-text {
  color: #494949;
  left: 0;
  position: relative;
}

.ftd-toggle-button-container {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  display: inline-flex;
  position: relative;
}
.ftd-toggle-button-container:not(.disabled):hover {
  background: rgba(255, 255, 255, 0.3);
}
.ftd-toggle-button-container label {
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  padding: 6px 16px;
  position: relative;
  transition: color 0.25s ease-in-out;
  z-index: 2;
}
.ftd-toggle-button-container label.active, .ftd-toggle-button-container label.inactive {
  color: #fff;
  text-align: center;
}
.ftd-toggle-button-container label.active.checked, .ftd-toggle-button-container label.inactive.checked {
  background: #fff;
  color: #000;
}
.ftd-toggle-button-container label mat-icon.ftd-toggle-button-active-icon,
.ftd-toggle-button-container label mat-icon.ftd-toggle-button-inactive-icon {
  margin-right: 4px;
}
.ftd-toggle-button-container.disabled {
  cursor: pointer;
  opacity: 0.3;
}
.ftd-toggle-button-container.disabled label {
  cursor: default;
}

.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 400px;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container .price-range-inputs-container app-ftd-input .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  text-align: right;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container .price-range-inputs-container app-ftd-input input {
  margin-left: -10px;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container .price-range-inputs-container app-ftd-input .mat-mdc-form-field-icon-prefix {
  padding: 0 4px 0 10px;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider {
  width: 235px;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider mat-slider-visual-thumb .mdc-slider__thumb-knob {
  background-color: #7b9ac0;
  border-color: #7b9ac0;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  background-color: #7b9ac0;
  border-color: #7b9ac0;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider .mat-mdc-slider {
  margin-left: 0;
  margin-right: 0;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider .mdc-slider__track {
  width: 245px;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider .mdc-slider__track .mdc-slider__track--active_fill {
  border-color: #7b9ac0;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider .mdc-slider__track .mdc-slider__track--inactive {
  background-color: #7b9ac0;
}
.cdk-overlay-container .mat-mdc-menu-panel.mat-mdc-menu-panel .price-range-select-slider-container mat-slider .mdc-slider__track .mdc-slider__track--inactive::before {
  background-color: #7b9ac0;
}

app-macroeconomics-page .mat-mdc-form-field {
  background-color: #fff;
  color: #000;
}
app-macroeconomics-page ftd-tab-group .mat-mdc-tab-label-container {
  flex-grow: 0;
}

#edit-prices-form #values-form-container {
  background-color: #303854;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
#edit-prices-form #values-form-container .mdc-text-field--focused {
  background-color: #000;
}
#edit-prices-form #values-form-container .mat-mdc-icon-button.mat-mdc-button-base {
  color: #fff;
  height: 24px;
  margin-right: 8px;
  padding: 0;
  width: 24px;
}

app-subsidies-and-taxation app-ftd-multi-dropdown .mat-mdc-select-value-text {
  color: #000;
}
app-subsidies-and-taxation app-ftd-input .mat-form-field {
  margin-bottom: 0px;
  width: 100%;
}
app-subsidies-and-taxation app-ftd-input .mat-mdc-form-field {
  background-color: #fff;
  color: #000;
}
app-subsidies-and-taxation app-ftd-input .mat-mdc-form-field.mat-focused.mat-primary {
  color: #000;
}
app-subsidies-and-taxation app-ftd-input .mdc-notched-outline {
  background-color: #fff;
}
app-subsidies-and-taxation mat-date-range-input {
  display: block;
  width: 0px;
}
app-subsidies-and-taxation mat-date-range-input:focus {
  border: 2px solid #008ff0;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-container {
  align-items: center;
  display: flex;
  padding: 2px 4px;
  width: 170px;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-container .mat-date-range-input-wrapper {
  color: #494949;
  height: 26px;
  width: 80px;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-container .mat-date-range-input-wrapper input {
  border: 2px solid transparent;
  height: 26px;
  margin: 0;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-container .mat-date-range-input-wrapper input:focus {
  border: 2px solid transparent;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-container .mat-date-range-input-wrapper input.input-locked {
  pointer-events: none;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-container .mat-date-range-input-separator {
  color: #494949;
  opacity: 1;
  z-index: 4;
}
app-subsidies-and-taxation mat-date-range-input .mat-date-range-input-mirror {
  color: #494949;
}

app-ftd-input .mat-mdc-form-field-icon-prefix > .mat-icon {
  padding: 0 4px;
}

app-markets-page .mat-mdc-form-field {
  color: #000;
}
app-markets-page ftd-tab-group .mat-mdc-tab-label-container {
  flex-grow: 0;
}

.mat-mdc-menu-content p,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text p {
  font-weight: var(--mdc-typography-body1-font-weight, 400);
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-icon {
  margin-right: 8px;
}

.main-container-fullscreen {
  background-color: #151829;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% + 17px);
  padding: 24px 32px;
}
.main-container-fullscreen .mat-drawer-container {
  height: 100%;
}
.main-container-fullscreen #matrix-view-container {
  height: 80%;
}
.main-container-fullscreen .mat-drawer-content {
  overflow: hidden;
}
.main-container-fullscreen .mat-drawer-content mat-card#main-container-content .mat-mdc-card-content {
  padding-bottom: 0;
}
.main-container-fullscreen .mat-drawer-content mat-card#main-container-content .mat-mdc-card-content app-matrix-view-data app-matrix-view div#matrix-view-container {
  height: 100%;
}

app-create-discount-modal app-ftd-date-picker .mat-mdc-text-field-wrapper {
  height: 40px;
}
app-create-discount-modal app-ftd-date-picker .mat-mdc-text-field-wrapper .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 32px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 4px;
}
app-create-discount-modal app-ftd-date-picker .mat-mdc-text-field-wrapper .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
app-create-discount-modal app-ftd-date-picker .mat-mdc-text-field-wrapper .mat-mdc-icon-button {
  color: white;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "BMW Group TN Condensed Pro", serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 2em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.5em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(1em * 0.83) / 1.25em "BMW Group TN Condensed Pro", serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(1em * 0.67) / 1.25em "BMW Group TN Condensed Pro", serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.25em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 112px;
  font-weight: 400;
  line-height: 112px;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-bottom-sheet-container-text-line-height: 1.25em;
  --mat-bottom-sheet-container-text-size: 1em;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-standard-button-toggle-text-font: "BMW Group TN Condensed Pro", serif;
}

.mat-calendar {
  font-family: "BMW Group TN Condensed Pro", serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 1em;
  font-weight: 700;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

html {
  --mat-expansion-header-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-expansion-header-text-size: 1em;
  --mat-expansion-header-text-weight: 700;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-expansion-container-text-line-height: 1.25em;
  --mat-expansion-container-text-size: 1em;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 1em;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 0.875em;
}

html {
  --mat-stepper-container-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-stepper-header-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-stepper-header-label-text-size: 1em;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 1em;
  --mat-stepper-header-selected-state-label-text-size: 1em;
  --mat-stepper-header-selected-state-label-text-weight: 700;
}

html {
  --mat-toolbar-title-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-toolbar-title-text-line-height: 1.5em;
  --mat-toolbar-title-text-size: 1.5em;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 700;
}

.mat-tree {
  font-family: "BMW Group TN Condensed Pro", serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 1em;
}

html {
  --mat-option-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-option-label-text-line-height: 1em;
  --mat-option-label-text-size: 1em;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 700;
}

html {
  --mat-optgroup-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-optgroup-label-text-line-height: 1em;
  --mat-optgroup-label-text-size: 1em;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 700;
}

.mat-mdc-card {
  --mat-card-title-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-card-title-text-line-height: 1.5em;
  --mat-card-title-text-size: 1.5em;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 700;
  --mat-card-subtitle-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-card-subtitle-text-line-height: 1.25em;
  --mat-card-subtitle-text-size: 1em;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 700;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-plain-tooltip-supporting-text-size: 0.875em;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-caption-font-size, 0.875em);
  line-height: var(--mdc-typography-caption-line-height, 1em);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 1em);
  font-weight: var(--mdc-typography-body1-font-weight, 700);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(1em * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1em;
}

html {
  --mat-select-trigger-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-select-trigger-text-line-height: 1em;
  --mat-select-trigger-text-size: 1em;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 700;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "BMW Group TN Condensed Pro", serif;
  --mdc-dialog-subhead-line-height: 1.5em;
  --mdc-dialog-subhead-size: 1.5em;
  --mdc-dialog-subhead-weight: 700;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-dialog-supporting-text-line-height: 1em;
  --mdc-dialog-supporting-text-size: 1em;
  --mdc-dialog-supporting-text-weight: 700;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-chip-label-text-line-height: 1.25em;
  --mdc-chip-label-text-size: 1em;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-slider-label-label-text-size: 1em;
  --mdc-slider-label-label-text-line-height: 1.25em;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 700;
}

html {
  --mat-menu-item-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-menu-item-label-text-size: 1em;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1em;
  --mat-menu-item-label-text-weight: 700;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-list-list-item-label-text-line-height: 1em;
  --mdc-list-list-item-label-text-size: 1em;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 700;
  --mdc-list-list-item-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-list-list-item-supporting-text-line-height: 1.25em;
  --mdc-list-list-item-supporting-text-size: 1em;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1em;
  --mdc-list-list-item-trailing-supporting-text-size: 0.875em;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.5em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-paginator-container-text-line-height: 1em;
  --mat-paginator-container-text-size: 0.875em;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.875em;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: "BMW Group TN Condensed Pro", serif;
  --mat-tab-header-label-text-size: 1em;
  --mat-tab-header-label-text-letter-spacing: normal;
  --mat-tab-header-label-text-line-height: 1.5em;
  --mat-tab-header-label-text-weight: 700;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 1.5em);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 1.5em);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-snackbar-supporting-text-line-height: 1.25em;
  --mdc-snackbar-supporting-text-size: 1em;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 1em);
  line-height: var(--mdc-typography-subtitle2-line-height, 1.25em);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

::-webkit-scrollbar {
  background: transparent;
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-thumb:vertical:hover {
  border: 1px;
  width: 16px;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  border: 20px;
  height: 20px;
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #7b8ec0 0%, #565b76 100%);
  border: 2px solid #151829;
  border-radius: 100vw;
}

::-webkit-scrollbar-track {
  background: #151829;
  width: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(to right, #7b8ec0 0%, #565b76 100%);
  border: 2px solid #1e2339;
}

::-webkit-scrollbar-corner {
  background: #151829;
}

/* Special workaround for scrolls within the body element */
body ::-webkit-scrollbar-thumb {
  border-color: #1e2339;
}
body ::-webkit-scrollbar-corner {
  background: transparent;
}
body ::-webkit-scrollbar-track {
  background: #1e2339;
}

html,
body {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

body {
  margin: 0;
}

.hidden {
  visibility: hidden;
}

.sr-only {
  display: none;
}

.ftd-theme {
  --mat-select-panel-background-color: #282f47;
}
.ftd-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.ftd-theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  min-height: 40px;
  padding: 0 8px;
}
.ftd-theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), .ftd-theme .mat-mdc-option:focus:not(.mdc-list-item--disabled) {
  background: #565b76;
  color: #fff;
}
.ftd-theme .mat-mdc-option.mat-mdc-option-active, .ftd-theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: #151829;
}
.ftd-theme .mat-mdc-option.mat-mdc-option-active:hover, .ftd-theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):hover {
  background: #565b76;
}
.ftd-theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #f48fb1);
  opacity: 1;
}
.ftd-theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
  opacity: 1;
}
.ftd-theme .mat-mdc-optgroup-label {
  color: #fff;
  background: #565b76;
  min-height: 16px;
  padding: 0 8px;
}
.ftd-theme .mat-pseudo-checkbox {
  color: #fff;
}
.ftd-theme .mat-pseudo-checkbox::after {
  color: #fff;
}
.ftd-theme .mat-pseudo-checkbox-disabled {
  color: #494949;
}
.ftd-theme .mat-primary .mat-pseudo-checkbox-checked,
.ftd-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: transparent;
  border-color: #fff;
}
.ftd-theme .mat-pseudo-checkbox-checked,
.ftd-theme .mat-pseudo-checkbox-indeterminate,
.ftd-theme .mat-accent .mat-pseudo-checkbox-checked,
.ftd-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #fff;
}
.ftd-theme .mat-warn .mat-pseudo-checkbox-checked,
.ftd-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d92424;
}
.ftd-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.ftd-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #494949;
}
.ftd-theme .mat-app-background, .ftd-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.ftd-theme .mat-elevation-z0,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z1,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z2,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z3,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z4,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z5,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z6,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z7,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z8,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z9,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z10,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z11,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z12,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z13,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z14,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z15,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z16,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z17,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z18,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z19,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z20,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z21,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z22,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z23,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-elevation-z24,
.ftd-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.ftd-theme .mat-mdc-option {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}
.ftd-theme .mat-mdc-checkbox .mdc-form-field {
  color: #fff;
}
.ftd-theme .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: transparent;
}
.ftd-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: #fff;
  opacity: 0;
}
.ftd-theme .mat-mdc-checkbox.mat-primary .mdc-checkbox__ripple {
  background: #3f51b5;
}
.ftd-theme .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #151829;
  --mdc-checkbox-selected-focus-icon-color: #fff;
  --mdc-checkbox-selected-hover-icon-color: #fff;
  --mdc-checkbox-selected-icon-color: #fff;
  --mdc-checkbox-selected-pressed-icon-color: #fff;
  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-disabled-selected-icon-color: #a8a8a7;
  --mdc-checkbox-disabled-unselected-icon-color: #a8a8a7;
  --mdc-checkbox-unselected-icon-color: #fff;
  --mdc-checkbox-unselected-pressed-icon-color: #fff;
}
.ftd-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: #fff;
  opacity: 0;
}
.ftd-theme .mat-mdc-checkbox.mat-warn .mdc-checkbox__ripple {
  background: #f44336;
}
.ftd-theme .mat-mdc-checkbox-disabled label {
  color: #a8a8a7;
}
.ftd-theme .mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 32px) - 32px) / 2);
}
.ftd-theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 32px) - 18px) / 2);
}
.ftd-theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  right: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  left: calc((32px - var(--mdc-checkbox-touch-target-size, 32px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 32px);
  height: var(--mdc-checkbox-touch-target-size, 32px);
}
@media all and (-ms-high-contrast: none) {
  .ftd-theme .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
.ftd-theme .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #a8a8a7;
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #fff;
}
@media all {
  .ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: #a8a8a7;
  }
}
@media all {
  .ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: #a8a8a7;
  }
}
.ftd-theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #3f51b5);
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: #fff;
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: #fff;
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: #fff;
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #fff;
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: #a8a8a7;
}
.ftd-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: #a8a8a7;
}
.ftd-theme .mdc-text-field__input {
  margin-top: 2px;
}
.ftd-theme .mdc-text-field--filled .mdc-text-field__ripple::before,
.ftd-theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, #fff);
}
.ftd-theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .ftd-theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.ftd-theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .ftd-theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.ftd-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.ftd-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #fff;
}
.ftd-theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #fff;
}
.ftd-theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #3f51b5);
}
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #fff;
}
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #0071c5;
}
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #0071c5;
}
[dir=rtl] .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ftd-theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
.ftd-theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .ftd-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .ftd-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.ftd-theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #a8a8a7;
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mdc-text-field--disabled .mdc-text-field__input {
  color: #494949;
}
@media all {
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: #494949;
  }
}
@media all {
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: #494949;
  }
}
.ftd-theme .mdc-text-field--disabled .mdc-floating-label {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-text-field-character-counter,
.ftd-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: #494949;
}
.ftd-theme .mdc-text-field--disabled .mdc-notched-outline__leading,
.ftd-theme .mdc-text-field--disabled .mdc-notched-outline__notch,
.ftd-theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: #494949;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  .ftd-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .ftd-theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  .ftd-theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  .ftd-theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.ftd-theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .ftd-theme .mdc-text-field--end-aligned .mdc-text-field__input, .ftd-theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .ftd-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .ftd-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.ftd-theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field-subscript-wrapper {
  color: #a8a8a7;
}
.ftd-theme :where(.mat-form-field-invalid) .mat-mdc-form-field-subscript-wrapper .custom-message {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field-focus-overlay {
  background-color: #fff;
}
.ftd-theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.ftd-theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.ftd-theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: #000;
}
.ftd-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(63, 81, 181, 0.87);
}
.ftd-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(244, 143, 177, 0.87);
}
.ftd-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.ftd-theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: #000;
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #f48fb1);
}
.ftd-theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #f48fb1);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #a8a8a7;
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #f48fb1);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #a8a8a7;
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.ftd-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.ftd-theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.ftd-theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.ftd-theme .mat-mdc-form-field {
  width: 100%;
}
.ftd-theme .mat-mdc-form-field-infix {
  min-height: 40px;
}
.ftd-theme .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.ftd-theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.ftd-theme .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-bottom: 8px;
  padding-top: 8px;
}
.ftd-theme .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 8px;
  padding-top: 24px;
}
.ftd-theme .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-bottom: 8px;
  padding-top: 8px;
}
.ftd-theme .mat-mdc-form-field-subscript-wrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
.ftd-theme .mat-mdc-form-field-hint-wrapper,
.ftd-theme .mat-mdc-form-field-error-wrapper {
  left: 0;
  padding: 0 8px;
  position: absolute;
  right: 0;
  top: 4px;
}
.ftd-theme .mat-mdc-form-field-subscript-dynamic-size .mat-mdc-form-field-hint-wrapper,
.ftd-theme .mat-mdc-form-field-subscript-dynamic-size .mat-mdc-form-field-error-wrapper {
  position: static;
}
.ftd-theme .mat-mdc-form-field-bottom-align {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.ftd-theme .mat-mdc-form-field-bottom-align .custom-message {
  margin-right: auto;
}
.ftd-theme .mat-mdc-form-field-bottom-align::before {
  content: "";
  display: none;
}
.ftd-theme .mat-mdc-form-field-bottom-align.mat-mdc-form-field-subscript-dynamic-size::before {
  content: unset;
}
.ftd-theme .mat-mdc-form-field-hint-end {
  order: 1;
}
.ftd-theme .mat-mdc-form-field-hint-wrapper {
  display: flex;
}
.ftd-theme .mat-mdc-form-field-hint-spacer {
  flex: 0;
}
.ftd-theme .mat-mdc-form-field-error {
  display: block;
}
.ftd-theme app-ftd-input mat-form-field {
  margin-bottom: 16px;
}
.ftd-theme app-ftd-input mat-form-field .mat-mdc-text-field-wrapper,
.ftd-theme app-ftd-textarea mat-form-field .mat-mdc-text-field-wrapper {
  padding-left: 8px;
  padding-right: 8px;
}
.ftd-theme app-ftd-input mat-form-field .mat-mdc-text-field-wrapper textarea,
.ftd-theme app-ftd-textarea mat-form-field .mat-mdc-text-field-wrapper textarea {
  min-height: 22px;
}
.ftd-theme app-ftd-input mat-form-field .mdc-text-field--focused,
.ftd-theme app-ftd-textarea mat-form-field .mdc-text-field--focused {
  background-color: #000;
  border-radius: 4px;
}
.ftd-theme .mdc-text-field__input {
  margin-top: 2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.ftd-theme .mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .ftd-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ftd-theme .mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .ftd-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .ftd-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .ftd-theme .mdc-text-field--end-aligned .mdc-text-field__input, .ftd-theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ftd-theme .mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .ftd-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .ftd-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.ftd-theme .mat-mdc-form-field-subscript-wrapper,
.ftd-theme .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-caption-font-size, 0.875em);
  line-height: var(--mdc-typography-caption-line-height, 1em);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
.ftd-theme .mat-mdc-form-field,
.ftd-theme .mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  line-height: 1.25rem;
}
.ftd-theme .mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(1em * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.ftd-theme .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1em;
}
.ftd-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #282f47;
  color: #fff;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-thumb {
  border-color: #282f47;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-corner {
  background: transparent;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-track {
  background: #282f47;
}
.ftd-theme .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item__secondary-text {
  color: #fff;
}
.ftd-theme .mdc-list-item__overline-text {
  color: #fff;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__start,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__content,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__end {
  1: 0.38;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #6f6f6f;
  opacity: 1;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.ftd-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #fff;
}
.ftd-theme .mdc-deprecated-list-group__subheader {
  color: #fff;
}
.ftd-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.ftd-theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-mdc-select-value {
  color: #fff;
}
.ftd-theme .mat-mdc-select-placeholder {
  color: #a8a8a7;
}
.ftd-theme .mat-mdc-select-disabled .mat-mdc-select-value .mat-mdc-select-placeholder, .ftd-theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: #494949;
}
.ftd-theme .mat-mdc-select-arrow {
  color: #fff;
}
.ftd-theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: #fff;
}
.ftd-theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: #fff;
}
.ftd-theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: #d92424;
}
.ftd-theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: #d92424;
}
.ftd-theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: #494949;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-thumb {
  border-color: #282f47;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-corner {
  background: transparent;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-track {
  background: #282f47;
}
.ftd-theme .mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
  /* @alternate */
  line-height: 1em;
  border-radius: 4px !important;
  padding: 0 !important;
}
.ftd-theme .mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #fff;
  --mdc-switch-disabled-unselected-handle-color: #fff;
  --mdc-switch-disabled-selected-track-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #fff;
  --mdc-switch-unselected-focus-state-layer-color: #fff;
  --mdc-switch-unselected-pressed-state-layer-color: #fff;
  --mdc-switch-unselected-hover-state-layer-color: #fff;
  --mdc-switch-unselected-focus-track-color: #fff;
  --mdc-switch-unselected-hover-track-color: #fff;
  --mdc-switch-unselected-pressed-track-color: #fff;
  --mdc-switch-unselected-track-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #d6d6d6;
  --mdc-switch-unselected-hover-handle-color: #d6d6d6;
  --mdc-switch-unselected-pressed-handle-color: #d6d6d6;
  --mdc-switch-handle-surface-color: transparent;
  --mdc-switch-unselected-handle-color: #d6d6d6;
  --mdc-switch-selected-icon-color: transparent;
  --mdc-switch-disabled-selected-icon-color: transparent;
  --mdc-switch-disabled-unselected-icon-color: transparent;
  --mdc-switch-unselected-icon-color: transparent;
}
.ftd-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ftd-theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #7b9ac0;
  --mdc-switch-selected-handle-color: #7b9ac0;
  --mdc-switch-selected-hover-state-layer-color: #7b9ac0;
  --mdc-switch-selected-pressed-state-layer-color: #7b9ac0;
  --mdc-switch-selected-focus-handle-color: #7b9ac0;
  --mdc-switch-selected-hover-handle-color: #7b9ac0;
  --mdc-switch-selected-pressed-handle-color: #7b9ac0;
  --mdc-switch-selected-focus-track-color: #5e738f;
  --mdc-switch-selected-hover-track-color: #5e738f;
  --mdc-switch-selected-pressed-track-color: #5e738f;
  --mdc-switch-selected-track-color: #5e738f;
}
.ftd-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #7b9ac0;
  --mdc-switch-selected-handle-color: #7b9ac0;
  --mdc-switch-selected-hover-state-layer-color: #7b9ac0;
  --mdc-switch-selected-pressed-state-layer-color: #7b9ac0;
  --mdc-switch-selected-focus-handle-color: #7b9ac0;
  --mdc-switch-selected-hover-handle-color: #7b9ac0;
  --mdc-switch-selected-pressed-handle-color: #7b9ac0;
  --mdc-switch-selected-focus-track-color: #5e738f;
  --mdc-switch-selected-hover-track-color: #5e738f;
  --mdc-switch-selected-pressed-track-color: #5e738f;
  --mdc-switch-selected-track-color: #5e738f;
}
.ftd-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #7b9ac0;
  --mdc-switch-selected-handle-color: #7b9ac0;
  --mdc-switch-selected-hover-state-layer-color: #7b9ac0;
  --mdc-switch-selected-pressed-state-layer-color: #7b9ac0;
  --mdc-switch-selected-focus-handle-color: #7b9ac0;
  --mdc-switch-selected-hover-handle-color: #7b9ac0;
  --mdc-switch-selected-pressed-handle-color: #7b9ac0;
  --mdc-switch-selected-focus-track-color: #5e738f;
  --mdc-switch-selected-hover-track-color: #5e738f;
  --mdc-switch-selected-pressed-track-color: #5e738f;
  --mdc-switch-selected-track-color: #5e738f;
}
.ftd-theme .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.ftd-theme .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mat-mdc-list-item-interactive::before {
  background: black;
}
.ftd-theme .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.ftd-theme .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #3f51b5;
  opacity: 0.08;
}
.ftd-theme .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.ftd-theme .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item__secondary-text {
  color: #fff;
}
.ftd-theme .mdc-list-item__overline-text {
  color: #fff;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__start,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__content,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__end {
  1: 0.38;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #6f6f6f;
  opacity: 1;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.ftd-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #fff;
}
.ftd-theme .mdc-deprecated-list-group__subheader {
  color: #fff;
}
.ftd-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.ftd-theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-mdc-list-option .mdc-list-item__start, .ftd-theme .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-mdc-list-option .mdc-list-item__start, .ftd-theme .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #3f51b5;
}
.ftd-theme .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .ftd-theme .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start, .ftd-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #f48fb1;
  --mdc-checkbox-selected-hover-icon-color: #f48fb1;
  --mdc-checkbox-selected-icon-color: #f48fb1;
  --mdc-checkbox-selected-pressed-icon-color: #f48fb1;
  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start, .ftd-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #f48fb1;
  --mdc-radio-selected-hover-icon-color: #f48fb1;
  --mdc-radio-selected-icon-color: #f48fb1;
  --mdc-radio-selected-pressed-icon-color: #f48fb1;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f48fb1;
}
.ftd-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .ftd-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-list-option.mat-warn .mdc-list-item__start, .ftd-theme .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #fff;
  --mdc-checkbox-unselected-hover-icon-color: #fff;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-mdc-list-option.mat-warn .mdc-list-item__start, .ftd-theme .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.ftd-theme .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .ftd-theme .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-one-line, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.ftd-theme .mat-mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines, .ftd-theme .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}
.ftd-theme .mat-mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}
.ftd-theme .mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.ftd-theme .mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}
.ftd-theme .mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
  /* @alternate */
  line-height: 1em;
}
.ftd-theme .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-overline-font-size, 12px);
  line-height: var(--mdc-typography-overline-line-height, 32px);
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: var(--mdc-typography-overline-text-transform, none);
}
.ftd-theme .mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-caption-font-size, 0.875em);
  line-height: var(--mdc-typography-caption-line-height, 1em);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
.ftd-theme .mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1.25em);
  line-height: var(--mdc-typography-subtitle1-line-height, 1.5em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.ftd-theme .mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-caption-font-size, 0.875em);
  line-height: var(--mdc-typography-caption-line-height, 1em);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
@media all and (-ms-high-contrast: none) {
  .ftd-theme .mat-mdc-list-option .mdc-list-item__start .mdc-checkbox .mdc-checkbox__focus-ring,
  .ftd-theme .mat-mdc-list-option .mdc-list-item__end .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
.ftd-theme .mat-mdc-list-item .mdc-list-item__primary-text {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}
.ftd-theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #fff;
}
.ftd-theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #0071c5;
}
.ftd-theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #d92424;
}
.ftd-theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: #565b76;
}
.ftd-theme .mdc-data-table__row {
  background-color: inherit;
}
.ftd-theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
.ftd-theme .mdc-data-table__row--selected {
  background-color: #565b76;
}
.ftd-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.ftd-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.ftd-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #565b76;
}
.ftd-theme .mdc-data-table__cell,
.ftd-theme .mdc-data-table__header-cell {
  border-bottom-color: #565b76;
}
.ftd-theme .mdc-data-table__pagination {
  border-top-color: #565b76;
}
.ftd-theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: #313b54;
}
.ftd-theme .mdc-data-table__header-cell {
  color: #fff;
}
.ftd-theme .mdc-data-table__pagination-total,
.ftd-theme .mdc-data-table__pagination-rows-per-page-label,
.ftd-theme .mdc-data-table__cell {
  color: #fff;
}
[dir=rtl] .ftd-theme .mdc-data-table__pagination-button .mdc-button__icon, .ftd-theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ftd-theme .mat-mdc-table {
  background: #1e2339;
}
.ftd-theme .mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.ftd-theme .mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.ftd-theme .mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}
.ftd-theme .mat-mdc-table .mdc-data-table__row {
  height: 40px;
}
.ftd-theme .mat-mdc-table .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
}
.ftd-theme .mat-mdc-table .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.ftd-theme .mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 1em);
  line-height: var(--mdc-typography-subtitle2-line-height, 1.25em);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 700);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}
[dir=rtl] .ftd-theme .mdc-data-table__pagination-button .mdc-button__icon, .ftd-theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.ftd-theme mat-table {
  display: block;
}
.ftd-theme mat-header-row {
  min-height: 40px;
}
.ftd-theme mat-row,
.ftd-theme mat-footer-row {
  min-height: 40px;
}
.ftd-theme mat-row,
.ftd-theme mat-header-row,
.ftd-theme mat-footer-row {
  align-items: center;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  box-sizing: border-box;
  display: flex;
}
.ftd-theme mat-cell:first-of-type,
.ftd-theme mat-header-cell:first-of-type,
.ftd-theme mat-footer-cell:first-of-type {
  padding-left: 24px;
}
[dir=rtl] .ftd-theme mat-cell:first-of-type:not(:only-of-type),
[dir=rtl] .ftd-theme mat-header-cell:first-of-type:not(:only-of-type),
[dir=rtl] .ftd-theme mat-footer-cell:first-of-type:not(:only-of-type) {
  padding-left: 0;
  padding-right: 24px;
}
.ftd-theme mat-cell:last-of-type,
.ftd-theme mat-header-cell:last-of-type,
.ftd-theme mat-footer-cell:last-of-type {
  padding-right: 24px;
}
[dir=rtl] .ftd-theme mat-cell:last-of-type:not(:only-of-type),
[dir=rtl] .ftd-theme mat-header-cell:last-of-type:not(:only-of-type),
[dir=rtl] .ftd-theme mat-footer-cell:last-of-type:not(:only-of-type) {
  padding-left: 24px;
  padding-right: 0;
}
.ftd-theme mat-cell,
.ftd-theme mat-header-cell,
.ftd-theme mat-footer-cell {
  align-items: center;
  display: flex;
  flex: 1;
  min-height: inherit;
  overflow: hidden;
  word-wrap: break-word;
}
.ftd-theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: inherit;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.ftd-theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .ftd-theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-label-text-color: inherit;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.ftd-theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .ftd-theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f48fb1;
  --mdc-chip-elevated-disabled-container-color: #f48fb1;
  --mdc-chip-label-text-color: inherit;
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}
.ftd-theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .ftd-theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: inherit;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.ftd-theme .mat-mdc-chip-focus-overlay {
  background: black;
}
.ftd-theme .mat-mdc-chip {
  height: 32px;
}
.ftd-theme .mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mat-mdc-chip-action-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  z-index: 0;
}
.ftd-theme .mdc-evolution-chip__action {
  cursor: pointer;
}
.ftd-theme .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  padding: 0;
}
.ftd-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1e2339;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: white;
  --mdc-dialog-supporting-text-color: white;
}
.ftd-theme .mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "BMW Group TN Condensed Pro", serif;
  --mdc-dialog-subhead-line-height: 1.5em;
  --mdc-dialog-subhead-size: 1.5em;
  --mdc-dialog-subhead-weight: 700;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-dialog-supporting-text-line-height: 1.25em;
  --mdc-dialog-supporting-text-size: 1em;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}
.ftd-theme .cdk-overlay-dark-backdrop {
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
}
.ftd-theme mat-dialog-container {
  background-color: transparent;
}
.ftd-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0071c5;
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.ftd-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: var(--mdc-linear-progress-track-color, #9ec7ff);
}
@media (forced-colors: active) {
  .ftd-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ftd-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%239ec7ff'/%3E%3C/svg%3E");
  }
}
.ftd-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--mdc-linear-progress-track-color, #9ec7ff);
}
.ftd-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #0071c5;
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.ftd-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: var(--mdc-linear-progress-track-color, #9ec7ff);
}
@media (forced-colors: active) {
  .ftd-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ftd-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%239ec7ff'/%3E%3C/svg%3E");
  }
}
.ftd-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: var(--mdc-linear-progress-track-color, #9ec7ff);
}
.ftd-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #d92424;
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.ftd-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: var(--mdc-linear-progress-track-color, #d47d7e);
}
@media (forced-colors: active) {
  .ftd-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ftd-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23d47d7e'/%3E%3C/svg%3E");
  }
}
.ftd-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: var(--mdc-linear-progress-track-color, #d47d7e);
}
.ftd-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-datepicker-toggle,
.ftd-theme .mat-datepicker-content .mat-calendar-next-button,
.ftd-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.ftd-theme .mat-calendar-table-header-divider {
  display: none;
}
.ftd-theme .mat-calendar-table-header-divider::after {
  background: transparent;
}
.ftd-theme .mat-calendar-table-header,
.ftd-theme .mat-calendar-body-label {
  color: #a8a8a7;
}
.ftd-theme .mat-calendar-body-cell-content,
.ftd-theme .mat-date-range-input-separator {
  border-color: transparent;
  color: #fff;
}
.ftd-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #6f6f6f;
}
.ftd-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: #6f6f6f;
}
.ftd-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.ftd-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #fff;
}
.ftd-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(111, 111, 111, 0.8);
}
.ftd-theme .mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.ftd-theme .mat-calendar-body-comparison-identical,
.ftd-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ftd-theme .mat-calendar-body-comparison-bridge-start::before,
.ftd-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ftd-theme .mat-calendar-body-comparison-bridge-end::before,
.ftd-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ftd-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ftd-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ftd-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ftd-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ftd-theme .mat-calendar-body-selected {
  background-color: #0071c5;
  color: white;
}
.ftd-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 113, 197, 0.4);
}
.ftd-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.ftd-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ftd-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #0071c5;
  border-color: #0071c5;
}
@media (hover: hover) {
  .ftd-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: transparent;
    border-color: #0071c5;
  }
}
.ftd-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #282f47;
  color: #fff;
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(244, 143, 177, 0.2);
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.ftd-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.ftd-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0071c5;
  color: rgba(0, 0, 0, 0.87);
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 113, 197, 0.4);
}
.ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.ftd-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ftd-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #0071c5;
  border-color: #0071c5;
}
@media (hover: hover) {
  .ftd-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: transparent;
    border-color: #0071c5;
  }
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.ftd-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.ftd-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #0071c5;
  color: white;
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 113, 197, 0.4);
}
.ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.ftd-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.ftd-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #0071c5;
  border-color: #0071c5;
}
@media (hover: hover) {
  .ftd-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: transparent;
    border-color: #0071c5;
  }
}
.ftd-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-datepicker-toggle-active {
  color: #3f51b5;
}
.ftd-theme .mat-datepicker-toggle-active.mat-accent {
  color: #f48fb1;
}
.ftd-theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.ftd-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.ftd-theme .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.ftd-theme .mat-calendar {
  font-family: "BMW Group TN Condensed Pro", serif;
}
.ftd-theme .mat-calendar-body {
  font-size: 14px;
}
.ftd-theme .mat-calendar-body-label {
  opacity: 0;
}
.ftd-theme .mat-calendar-body-label[colspan="7"] {
  display: none;
}
.ftd-theme .mat-calendar-period-button {
  font-size: 16px;
  font-weight: 700;
}
.ftd-theme .mat-calendar-table-header th {
  font-size: 14px;
  font-weight: 700;
}
.ftd-theme .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: palette.$ftd-white;
  --mdc-snackbar-container-color: #282f47;
  --mdc-snackbar-supporting-text-color: #fff;
}
.ftd-theme .mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-snackbar-supporting-text-line-height: 1.25em;
  --mdc-snackbar-supporting-text-size: 1em;
  --mdc-snackbar-supporting-text-weight: 400;
}
.ftd-theme .mat-divider {
  border-top-color: #565b76;
}
.ftd-theme .mat-divider-vertical {
  border-right-color: #565b76;
}
.ftd-theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.ftd-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #3f51b5;
}
.ftd-theme .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #f48fb1;
  --mdc-radio-selected-hover-icon-color: #f48fb1;
  --mdc-radio-selected-icon-color: #f48fb1;
  --mdc-radio-selected-pressed-icon-color: #f48fb1;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f48fb1;
}
.ftd-theme .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #fff;
  --mdc-radio-unselected-icon-color: #fff;
  --mdc-radio-unselected-pressed-icon-color: #fff;
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.ftd-theme .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.ftd-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.ftd-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}
.ftd-theme .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.ftd-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #282f47;
  color: #fff;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-thumb {
  border-color: #282f47;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-corner {
  background: transparent;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-track {
  background: #282f47;
}
.ftd-theme .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item__secondary-text {
  color: #fff;
}
.ftd-theme .mdc-list-item__overline-text {
  color: #fff;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__start,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__content,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__end {
  1: 0.38;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #6f6f6f;
  opacity: 1;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.ftd-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #fff;
}
.ftd-theme .mdc-deprecated-list-group__subheader {
  color: #fff;
}
.ftd-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.ftd-theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-thumb {
  border-color: #282f47;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-corner {
  background: transparent;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-track {
  background: #282f47;
}
.ftd-theme .mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
  /* @alternate */
  line-height: 1em;
}
.ftd-theme .mat-badge {
  position: relative;
}
.ftd-theme .mat-badge.mat-badge {
  overflow: visible;
}
.ftd-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.ftd-theme .mat-badge-content {
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  transform: scale(0.6);
  transition: transform 200ms ease-in-out;
  white-space: nowrap;
}
.ftd-theme .ng-animate-disabled .mat-badge-content,
.ftd-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.ftd-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.ftd-theme .mat-badge-small .mat-badge-content {
  height: 16px;
  line-height: 16px;
  width: 16px;
}
.ftd-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.ftd-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.ftd-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .ftd-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.ftd-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .ftd-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  left: -16px;
  right: auto;
}
.ftd-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .ftd-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.ftd-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .ftd-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  left: -8px;
  right: auto;
}
.ftd-theme .mat-badge-medium .mat-badge-content {
  height: 22px;
  line-height: 22px;
  width: 22px;
}
.ftd-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.ftd-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.ftd-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .ftd-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.ftd-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .ftd-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  left: -22px;
  right: auto;
}
.ftd-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .ftd-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.ftd-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .ftd-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  left: -11px;
  right: auto;
}
.ftd-theme .mat-badge-large .mat-badge-content {
  height: 28px;
  line-height: 28px;
  width: 28px;
}
.ftd-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.ftd-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.ftd-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .ftd-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.ftd-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .ftd-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  left: -28px;
  right: auto;
}
.ftd-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .ftd-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.ftd-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .ftd-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  left: -14px;
  right: auto;
}
.ftd-theme .mat-badge-content {
  background: #0071c5;
}
.cdk-high-contrast-active .ftd-theme .mat-badge-content {
  border-radius: 0;
  outline: solid 1px;
}

.ftd-theme .mat-badge-accent .mat-badge-content {
  background: #0071c5;
}
.ftd-theme .mat-badge-warn .mat-badge-content {
  background: #0071c5;
}
.ftd-theme .mat-badge-disabled .mat-badge-content {
  background: #494949;
  color: #494949;
}
.ftd-theme .mat-badge-content {
  font-family: "BMW Group TN Condensed Pro", serif;
  font-size: 12px;
  font-weight: 600;
}
.ftd-theme .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.ftd-theme .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.ftd-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.ftd-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #fff;
}
.ftd-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #0071c5;
}
.ftd-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #d92424;
}
.ftd-theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.38);
}
.ftd-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #fff;
}
.ftd-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #7b9ac0;
}
.ftd-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #0071c5;
  --mdc-filled-button-label-text-color: #0071c5;
}
.ftd-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #d92424;
  --mdc-filled-button-label-text-color: #d92424;
}
.ftd-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.38);
}
.ftd-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #fff;
}
.ftd-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #7b9ac0;
}
.ftd-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #0071c5;
  --mdc-protected-button-label-text-color: #0071c5;
}
.ftd-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #d92424;
  --mdc-protected-button-label-text-color: #d92424;
}
.ftd-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.ftd-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.ftd-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #fff;
}
.ftd-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #0071c5;
}
.ftd-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #d92424;
}
.ftd-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.ftd-theme .mat-mdc-button,
.ftd-theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ftd-theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ftd-theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-button.mat-primary,
.ftd-theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-button.mat-accent,
.ftd-theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #0071c5;
  --mat-mdc-button-ripple-color: rgba(0, 113, 197, 0.1);
}
.ftd-theme .mat-mdc-button.mat-warn,
.ftd-theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #d92424;
  --mat-mdc-button-ripple-color: rgba(217, 36, 36, 0.1);
}
.ftd-theme .mat-mdc-raised-button,
.ftd-theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ftd-theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ftd-theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-raised-button.mat-primary,
.ftd-theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-raised-button.mat-accent,
.ftd-theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #0071c5;
  --mat-mdc-button-ripple-color: rgba(0, 113, 197, 0.1);
}
.ftd-theme .mat-mdc-raised-button.mat-warn,
.ftd-theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #d92424;
  --mat-mdc-button-ripple-color: rgba(217, 36, 36, 0.1);
}
.ftd-theme .mat-mdc-button.mat-mdc-button-base,
.ftd-theme .mat-mdc-raised-button.mat-mdc-button-base,
.ftd-theme .mat-mdc-unelevated-button.mat-mdc-button-base,
.ftd-theme .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.ftd-theme .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 1.5em);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.ftd-theme .mat-mdc-fab,
.ftd-theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ftd-theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ftd-theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.ftd-theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-fab.mat-primary,
.ftd-theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-fab.mat-accent,
.ftd-theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #0071c5;
  --mat-mdc-button-ripple-color: rgba(0, 113, 197, 0.1);
}
.ftd-theme .mat-mdc-fab.mat-warn,
.ftd-theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #d92424;
  --mat-mdc-button-ripple-color: rgba(217, 36, 36, 0.1);
}
.ftd-theme .mat-mdc-fab.mat-unthemed,
.ftd-theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.ftd-theme .mat-mdc-fab.mat-primary,
.ftd-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.ftd-theme .mat-mdc-fab.mat-accent,
.ftd-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #f48fb1;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.ftd-theme .mat-mdc-fab.mat-warn,
.ftd-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.ftd-theme .mat-mdc-fab[disabled][disabled],
.ftd-theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 1.5em);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.ftd-theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ftd-theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ftd-theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ftd-theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ftd-theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #0071c5;
  --mat-mdc-button-ripple-color: rgba(0, 113, 197, 0.1);
}
.ftd-theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #d92424;
  --mat-mdc-button-ripple-color: rgba(217, 36, 36, 0.1);
}
.ftd-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
}
.ftd-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f48fb1;
}
.ftd-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.ftd-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.ftd-theme .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.ftd-theme .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.ftd-theme .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.ftd-theme .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.ftd-theme .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
}
.ftd-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #282f47;
  color: #fff;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-thumb {
  border-color: #282f47;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-corner {
  background: transparent;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-track {
  background: #282f47;
}
.ftd-theme .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item__secondary-text {
  color: #fff;
}
.ftd-theme .mdc-list-item__overline-text {
  color: #fff;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.ftd-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item__end {
  color: #fff;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__start,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__content,
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__end {
  1: 0.38;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #6f6f6f;
  opacity: 1;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #6f6f6f;
}
.ftd-theme .mdc-list-item--selected .mdc-list-item__primary-text,
.ftd-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #fff;
  opacity: 1;
}
.ftd-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.ftd-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #fff;
}
.ftd-theme .mdc-deprecated-list-group__subheader {
  color: #fff;
}
.ftd-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
.ftd-theme .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-mdc-menu-item[disabled],
.ftd-theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.ftd-theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.ftd-theme .mat-mdc-menu-item .mat-icon-no-color,
.ftd-theme .mat-mdc-menu-submenu-icon {
  color: #fff;
}
.ftd-theme .mat-mdc-menu-item:hover:not([disabled]),
.ftd-theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.ftd-theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.ftd-theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-thumb {
  border-color: #282f47;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-corner {
  background: transparent;
}
.ftd-theme .mdc-menu-surface::-webkit-scrollbar-track {
  background: #282f47;
}
.ftd-theme .mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 1.25em);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
  /* @alternate */
  line-height: 1em;
  background-color: #282f47;
  color: #fff;
  padding: 0;
}
.ftd-theme .mat-mdc-menu-content,
.ftd-theme .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 1em);
  font-weight: var(--mdc-typography-body1-font-weight, 700);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.ftd-theme .mat-expansion-panel {
  background: transparent;
  border-bottom: 1px solid #565b76;
  border-top: 1px solid #565b76;
  color: #fff;
  margin: 8px;
}
.ftd-theme #modal-accordion .mat-expansion-panel {
  background: transparent;
  border-bottom: 1px solid #565b76;
  border-top: 0;
  color: #fff;
  margin: 0px;
}
.ftd-theme #modal-accordion .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}
.ftd-theme #modal-accordion .mat-expansion-panel .mat-expansion-panel-header {
  height: 40px;
}
.ftd-theme #modal-accordion .mat-expansion-panel .mdc-data-table__header-row {
  height: 40px;
}
.ftd-theme #modal-accordion .mat-expansion-panel .mdc-data-table__cell {
  padding: 0 8px;
}
.ftd-theme #modal-accordion .mat-expansion-panel .mdc-data-table__cell.no-padding {
  padding: 0;
}
.ftd-theme #modal-accordion .mat-expansion-panel .mdc-data-table__header-cell {
  border-top: 1px solid #565b76;
  padding: 0 8px;
}
.ftd-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.ftd-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .ftd-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .ftd-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .ftd-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.ftd-theme .mat-expansion-panel-header-title {
  color: #fff;
}
.ftd-theme .mat-expansion-panel-header-description,
.ftd-theme .mat-expansion-indicator::after {
  color: #fff;
  font-weight: 400;
  margin: 0;
}
.ftd-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.ftd-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.ftd-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.ftd-theme .mat-expansion-panel-header {
  height: 48px;
  padding: 0 8px;
}
.ftd-theme .mat-expansion-panel-header.mat-expanded {
  height: 48px;
}
.ftd-theme .mat-expansion-panel-header {
  font-family: "BMW Group TN Condensed Pro", serif;
  font-size: 1em;
  font-weight: 700;
}
.ftd-theme .mat-expansion-panel-content {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
}
.ftd-theme .mat-accordion .mat-expansion-panel:first-of-type,
.ftd-theme .mat-accordion .mat-expansion-panel:last-of-type {
  border-radius: 0;
}
.ftd-theme .mat-expansion-panel-body {
  padding: 0 8px;
}
.ftd-theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #282f47;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.ftd-theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: "BMW Group TN Condensed Pro", serif;
  --mdc-plain-tooltip-supporting-text-size: 0.875em;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link {
  background-color: #282f47;
  transition: all 0.3s ease-out;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: #fff;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab.mat-mdc-tab-disabled,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link.mat-mdc-tab-disabled {
  cursor: not-allowed;
  opacity: 1;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: #282f47;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab.mat-mdc-tab-disabled ftd-tab-label li,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link.mat-mdc-tab-disabled ftd-tab-label li {
  opacity: 0.5;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, transparent);
}
.ftd-theme .ftd-tabs-panel .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header-pagination .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-link .mat-ripple-element {
  background-color: var(--mdc-theme-surface, #fff);
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, transparent);
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: var(--mdc-theme-surface, #fff);
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, transparent);
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: var(--mdc-theme-surface, #fff);
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-background-primary,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #3f51b5;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-background-accent,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #f48fb1;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-group.mat-background-warn,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-surface, #fff);
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header .mdc-tab {
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
  height: 54px;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header .mdc-tab--active {
  background-color: #303854;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__content,
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label {
  flex-grow: 1;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-header .mdc-tab__content {
  pointer-events: all;
}
.ftd-theme .ftd-tabs-panel .mat-mdc-tab-body {
  background-color: #303854;
  padding: 24px;
}
.ftd-theme .ftd-tabs-panel .mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "BMW Group TN Condensed Pro", serif));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 1.5em);
  font-weight: var(--mdc-typography-button-font-weight, 700);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.ftd-theme .ftd-tabs-panel ftd-tab-label {
  width: 100%;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 100%;
  min-height: 54px;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container {
  margin-right: 30px;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul li:not(:last-child) {
  padding-right: 5px;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul .title {
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-surface, #fff);
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul .info {
  margin-bottom: -2px;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul .info mat-icon {
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 20px;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul .info mat-icon svg,
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label .ftd-tab-title-container ul .info mat-icon img {
  width: 20px;
  height: 20px;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label div.ftd-tab-label-info {
  align-items: center;
  display: flex;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label div.ftd-tab-label-info div.percentage {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1em;
  font-family: "BMW Group TN Condensed Pro", serif;
  letter-spacing: normal;
  color: #d6d6d6;
}
.ftd-theme .ftd-tabs-panel ftd-tab-label div.ftd-tab-label div.ftd-tab-label-info #tab-actions-divider {
  border-left: 1px solid #d6d6d6;
  height: 16px;
  margin: 0 8px 0 4px;
}
.ftd-theme mat-snack-bar-container.mat-mdc-snack-bar-container {
  box-sizing: border-box;
  display: block;
  max-width: 480px;
  min-height: 56px;
  min-width: 240px;
  padding: 16px;
  transform-origin: center;
}
.ftd-theme mat-snack-bar-container app-ftd-snackbar p {
  margin-block: 0.2rem 0;
  text-transform: none;
}
.ftd-theme mat-snack-bar-container.info .ftd-snackbar-icon {
  color: #008ff0;
}
.ftd-theme mat-snack-bar-container.success .ftd-snackbar-icon {
  color: #078007;
}
.ftd-theme mat-snack-bar-container.error .ftd-snackbar-icon {
  color: #d92424;
}
.ftd-theme mat-snack-bar-container.warning .ftd-snackbar-icon {
  color: #f6c800;
}
.ftd-theme mat-snack-bar-container.mat-mdc-snack-bar-container.ftd-actions-toolbar {
  box-sizing: border-box;
  display: block;
  margin: 0 2.5rem;
  max-width: 70vw;
  min-height: 3.5rem;
  min-width: 70vw;
  padding: 0 1rem 1rem;
  transform-origin: center;
}
.ftd-theme mat-snack-bar-container.mat-mdc-snack-bar-container.ftd-actions-toolbar > div {
  background-color: #151829;
  box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
  min-width: 100%;
  width: 100%;
}
.ftd-theme mat-snack-bar-container app-ftd-snackbar p {
  margin-block: 0.2rem 0;
  text-transform: none;
}
.ftd-theme mat-snack-bar-container.info .ftd-snackbar-icon {
  color: #008ff0;
}
.ftd-theme mat-snack-bar-container.success .ftd-snackbar-icon {
  color: #078007;
}
.ftd-theme mat-snack-bar-container.error .ftd-snackbar-icon {
  color: #d92424;
}
.ftd-theme mat-snack-bar-container.warning .ftd-snackbar-icon {
  color: #f6c800;
}
.ftd-theme .mat-mdc-button.ftd-button {
  align-items: center;
  display: flex;
  font-family: "BMW Group TN Condensed Pro", serif;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0;
  text-transform: uppercase;
}
.ftd-theme .mat-mdc-button.ftd-button-base {
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-base:hover {
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-base:active {
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-primary {
  background: #7b9ac0;
  border: 1px solid #7b9ac0;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-primary:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #7b9ac0;
}
.ftd-theme .mat-mdc-button.ftd-button-primary:active {
  background: #33354e;
  border: 1px solid #33354e;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-secondary {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-secondary:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #33354e;
}
.ftd-theme .mat-mdc-button.ftd-button-secondary:active {
  background: #33354e;
  border: 1px solid #fff;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-terciary {
  background: transparent;
  border: 1px solid #7b9ac0;
  color: #7b9ac0;
}
.ftd-theme .mat-mdc-button.ftd-button-terciary:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #33354e;
}
.ftd-theme .mat-mdc-button.ftd-button-terciary:active {
  background: #33354e;
  border: 1px solid #7b9ac0;
  color: #7b9ac0;
}
.ftd-theme .mat-mdc-button.ftd-button-quarternary {
  background: transparent;
  border: 1px solid transparent;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-quarternary:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #33354e;
}
.ftd-theme .mat-mdc-button.ftd-button-quarternary:active {
  background: #33354e;
  border: 1px solid #33354e;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-green {
  background: #078007;
  border: 1px solid #078007;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-green:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #078007;
}
.ftd-theme .mat-mdc-button.ftd-button-green:active {
  background: #078007;
  border: 1px solid #078007;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-red {
  background: #d92424;
  border: 1px solid #d92424;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button-red:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #d92424;
}
.ftd-theme .mat-mdc-button.ftd-button-red:active {
  background: #d92424;
  border: 1px solid #d92424;
  color: #fff;
}
.ftd-theme .mat-mdc-button.ftd-button:disabled, .ftd-theme .mat-mdc-button.ftd-button:disabled:hover {
  background: #d6d6d6;
  border: 1px solid #d6d6d6;
  color: #a8a8a7;
  cursor: default;
}
.ftd-theme .mat-mdc-button.ftd-button .mat-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
}
.ftd-theme .mat-mdc-button.ftd-button-icon-left {
  margin-right: 6px;
}
.ftd-theme .mat-mdc-button.ftd-button-icon-right {
  margin-left: 6px;
}
.ftd-theme .mat-mdc-button.ftd-button-size-l {
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  height: 48px;
  min-width: 81px;
  padding: 12px;
}
.ftd-theme .mat-mdc-button.ftd-button-size-m {
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  min-width: 66px;
  padding: 12px 8px;
}
.ftd-theme .mat-mdc-button.ftd-button-size-s {
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  height: 32px;
  min-width: 62px;
  padding: 8px 6px;
}
.ftd-theme .mat-mdc-button.ftd-button-only-icon {
  min-width: 38px;
}
.ftd-theme .mat-mdc-button.ftd-button-only-icon .mat-icon {
  margin: 0;
}
.ftd-theme .mat-mdc-button.ftd-button-fill-width {
  margin: auto;
  width: 100%;
}
.ftd-theme .mat-mdc-button.ftd-button .mdc-button__label {
  display: inline-flex;
}
.ftd-theme .mat-mdc-button.ftd-button .mdc-button__label span {
  display: inline-flex;
}