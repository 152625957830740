@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use '@angular/material' as mat;
@use 'mdc/chips/chips' as mdc-chip-theme;
@use '@material/chips/chip-set' as mdc-chip-set;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/theme/color-palette' as mdc-color-palette;
@use '@material/typography' as mdc-typography;
@use 'core/palette' as ftd-colors;

@mixin _chip-variant($background, $foreground) {
  @include mdc-chip-theme.theme(
    (
      elevated-container-color: $background,
      elevated-disabled-container-color: $background,
      label-text-color: inherit,
      disabled-label-text-color: $foreground,
      with-icon-icon-color: $foreground,
      with-icon-disabled-icon-color: $foreground,
      with-trailing-icon-disabled-trailing-icon-color: $foreground,
      with-trailing-icon-trailing-icon-color: $foreground,
      with-icon-selected-icon-color: $foreground,
    )
  );
}

@mixin _colored-chip($palette) {
  $background: mat.get-color-from-palette($palette);
  $foreground: mat.get-color-from-palette($palette, default-contrast);

  &.mat-mdc-chip-selected,
  &.mat-mdc-chip-highlighted {
    @include _chip-variant($background, $foreground);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $is-dark: map.get($config, is-dark);

  @include mat.private-using-mdc-theme($config) {
    $on-surface: mdc-theme-color.prop-value(on-surface);
    $surface: mdc-theme-color.prop-value(surface);

    .mat-mdc-standard-chip {
      $standard-background: if(
        meta.type-of($on-surface) == color and meta.type-of($surface) == color,
        color.mix($on-surface, $surface, 12%),
        $on-surface
      );

      @include _chip-variant(
        $standard-background,
        if($is-dark, mdc-color-palette.$grey-50, mdc-color-palette.$grey-900)
      );

      &.mat-primary {
        @include _colored-chip($primary);
      }

      &.mat-accent {
        @include _colored-chip($accent);
      }

      &.mat-warn {
        @include _colored-chip($warn);
      }
    }
  }

  .mat-mdc-chip-focus-overlay {
    background: map.get($foreground, base);
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mdc-chip-set.core-styles($query: mat.$private-mdc-typography-styles-query);

  @include mat.private-using-mdc-typography($config) {
    // Note that we don't go through MDC's typography mixin, because it assigns the styles to
    // an inner element which makes it difficult for clients to customize. Instead we apply the
    // same styles ourselves to the root.
    .mat-mdc-standard-chip {
      @include mdc-typography.typography(body2, $query: mat.$private-mdc-typography-styles-query);
    }
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  .mat-mdc-chip {
    @include mdc-chip-theme.density($density-scale, $query: mat.$private-mdc-base-styles-query);
  }
}

@mixin ftd-chips($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-chips') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }

    .mat-mdc-chip-action-label {
      align-items: center;
      cursor: pointer;
      display: flex;
      z-index: 0;
    }

    .mdc-evolution-chip__action {
      cursor: pointer;
    }

    .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
      padding: 0;
    }
  }
}
