@use '@angular/material' as mat;
@use 'sass:map';
@use './ripple';
@use './option-theme';
@use './optgroup-theme';
@use '../pseudo-checkbox';
@use 'core/palette';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include ripple.color($config);
  @include option-theme.color($config);
  @include optgroup-theme.color($config);
  @include pseudo-checkbox.color(palette.$ftd-color-map);

  // Wrapper element that provides the theme background when the user's content isn't
  // inside of a `mat-sidenav-container`. Note that we need to exclude the ampersand
  // selector in case the mixin is included at the top level.
  .mat-app-background#{if(&, ', &.mat-app-background', '')} {
    $background: map.get($config, background);
    $foreground: map.get($config, foreground);

    background-color: mat.get-color-from-palette($background, background);
    color: mat.get-color-from-palette($foreground, text);
  }

  // Provides external CSS classes for each elevation value. Each CSS class is formatted as
  // `mat-elevation-z$zValue` where `$zValue` corresponds to the z-space to which the element is
  // elevated.
  @for $zValue from 0 through 24 {
    $selector: mat-elevation-z + $zValue;

    // We need the `mat-mdc-elevation-specific`, because some MDC mixins
    // come with elevation baked in and we don't have a way of removing it.
    .#{$selector},
    .mat-mdc-elevation-specific.#{$selector} {
      @include mat.private-theme-elevation($zValue, $config);
    }
  }

  // Marker that is used to determine whether the user has added a theme to their page.
  @at-root {
    .mat-theme-loaded-marker {
      display: none;
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include option-theme.typography($config);
  @include optgroup-theme.typography($config);
  @include pseudo-checkbox.typography($config);
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  @include option-theme.density($density-scale);
  @include optgroup-theme.density($density-scale);
}

// Mixin that renders all of the core styles that depend on the theme.
@mixin ftd-core($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  // Wrap the sub-theme includes in the duplicate theme styles mixin. This ensures that
  // there won't be multiple warnings. e.g. if `mat-core-theme` reports a warning, then
  // the imported themes (such as `mat-ripple-theme`) should not report again.
  @include mat.private-check-duplicate-theme-styles($theme, 'mat-core') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
