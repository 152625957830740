@use '@angular/material' as mat;
@use 'sass:map';
@use 'core/palette';

@mixin color($config) {
  $base: map.get($config, ftd);
  $primary: map.get($config, default);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $disabled: map.get($config, disabled);

  .mat-pseudo-checkbox {
    color: map.get($config, text);

    &::after {
      color: map.get($base, text);
    }
  }

  .mat-pseudo-checkbox-disabled {
    color: $disabled;
  }

  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: map.get($config, background);
    border-color: map.get($base, text);
  }

  // Default to the accent color. Note that the pseudo checkboxes are meant to inherit the
  // theme from their parent, rather than implementing their own theming, which is why we
  // don't attach to the `mat-*` classes. Also note that this needs to be below `.mat-primary`
  // in order to allow for the color to be overwritten if the checkbox is inside a parent that
  // has `mat-accent` and is placed inside another parent that has `mat-primary`.
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: map.get($accent, icon);
  }

  .mat-warn .mat-pseudo-checkbox-checked,
  .mat-warn .mat-pseudo-checkbox-indeterminate {
    background: map.get($warn, icon);
  }

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    &.mat-pseudo-checkbox-disabled {
      background: $disabled;
    }
  }
}

@mixin typography($config-or-theme) {
  // extends typography
}

@mixin _density($config-or-theme) {
  // extends density
}

@mixin ftd-pseudo-checkbox($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-pseudo-checkbox') {
    $color: palette.$ftd-color-map;

    @if $color != null {
      @include color($color);
    }
  }

  .mat-checkbox-frame:not(.mat-checkbox-disabled) {
    border-color: palette.$ftd-white;
  }

  .mat-checkbox-disabled {
    .mat-checkbox-frame {
      border-color: palette.$ftd-grey-500;
    }
  }

  .mat-checkbox-checked .mat-checkbox-inner-container {
    .mat-checkbox-frame {
      border-width: 0;
    }

    .mat-checkbox-background {
      background-color: palette.$ftd-white;

      svg path {
        stroke: palette.$ftd-bg-elevation;
        stroke-width: 4px;
      }
    }
  }
}
