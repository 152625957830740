@use 'core/palette';

#comments-container {
  max-height: 38vh;
  overflow: auto;

  .comments-container-item {
    .own-user-comment {
      color: palette.$ftd-caution-100;
    }

    .user-comment {
      color: palette.$ftd-info-100;
    }
  }
}
