// stylelint-disable selector-class-pattern --
// Selector '.mdc-*' should only be used in this project.

@use 'sass:math';
@use '@material/rtl/rtl';
@use '@material/theme/theme';
@use '@material/feature-targeting/feature-targeting';
@use './variables';

// Public mixins

@mixin icon-core-styles($query: feature-targeting.all()) {
  .mdc-text-field__icon {
    @include icon_($query: $query);

    svg {
      @include _icon-svg($query: $query);
    }
  }

  .mdc-text-field__icon--leading {
    @include leading-icon_($query: $query);
  }

  .mdc-text-field__icon--trailing {
    @include trailing-icon_($query: $query);
  }
}

///
/// Customizes the color for the leading icon in an enabled text-field.
/// @param {Color} $color - The desired icon color.
///
@mixin leading-icon-color($color, $query: feature-targeting.all()) {
  &:not(.mdc-text-field--disabled) {
    @include leading-icon-color_($color, $query);
  }
}

///
/// Customizes the color for the trailing icon in an enabled text-field.
/// @param {Color} $color - The desired icon color.
///
@mixin trailing-icon-color($color, $query: feature-targeting.all()) {
  &:not(.mdc-text-field--disabled) {
    @include trailing-icon-color_($color, $query);
  }
}

///
/// Customizes the color for the leading icons in a disabled text-field.
/// @param {Color} $color - The desired icon color.
///
@mixin disabled-leading-icon-color($color, $query: feature-targeting.all()) {
  &.mdc-text-field--disabled {
    @include leading-icon-color_($color, $query);
  }
}

///
/// Customizes the color for the trailing icons in a disabled text-field.
/// @param {Color} $color - The desired icon color.
///
@mixin disabled-trailing-icon-color($color, $query: feature-targeting.all()) {
  &.mdc-text-field--disabled {
    @include trailing-icon-color_($color, $query);
  }
}

///
/// Customizes the color for the leading/trailing icons in a disabled text-field.
/// @param {Color} $color - The desired icon color.
///
@mixin disabled-icon-color($color, $query: feature-targeting.all()) {
  @include disabled-leading-icon-color($color, $query);
  @include disabled-trailing-icon-color($color, $query);
}

/// Sets the size of the leading and trailing icons.
///
/// @param {Number} $size - the size of the icon in px
@mixin size($size, $query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    .mdc-text-field__icon {
      font-size: $size;
    }
  }
}

// Private mixins

@mixin icon_($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);
  $feat-color: feature-targeting.create-target($query, color);

  @include feature-targeting.targets($feat-structure) {
    align-self: center;
    cursor: pointer;
  }

  &:not([tabindex]),
  &[tabindex='-1'] {
    @include feature-targeting.targets($feat-color) {
      cursor: default;
      pointer-events: none;
    }
  }
}

@mixin _icon-svg($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    // SVG children can cause misalignment when displayed as inline since
    // line-height will be inherited and cause additional vertical space.
    // Setting the display to block prevents this.
    display: block;
  }
}

@mixin leading-icon_($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    @include rtl.reflexive-property(
      margin,
      variables.$leading-icon-padding-left,
      variables.$leading-icon-padding-right
    );
  }
}

@mixin trailing-icon_($query: feature-targeting.all()) {
  $feat-structure: feature-targeting.create-target($query, structure);

  @include feature-targeting.targets($feat-structure) {
    $padding: math.div(variables.$touch-target-size - variables.$icon-size, 2);
    $left-margin: variables.$trailing-icon-padding-left - $padding;
    $right-margin: variables.$trailing-icon-padding-right - $padding;

    padding: $padding;

    @include rtl.reflexive-property(margin, $left-margin, $right-margin);
  }
}

@mixin leading-icon-color_($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  .mdc-text-field__icon--leading {
    @include feature-targeting.targets($feat-color) {
      @include theme.property(color, $color);
    }
  }
}

@mixin trailing-icon-color_($color, $query: feature-targeting.all()) {
  $feat-color: feature-targeting.create-target($query, color);

  .mdc-text-field__icon--trailing {
    @include feature-targeting.targets($feat-color) {
      @include theme.property(color, $color);
    }
  }
}
