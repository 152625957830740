@use 'sass:map';
@use '@angular/material' as mat;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/theme/theme' as mdc-theme;
@use '@material/tab-indicator' as mdc-tab-indicator;
@use '@material/tab-indicator/tab-indicator-theme' as mdc-tab-indicator-theme;
@use '@material/tab' as mdc-tab;
@use '@material/tab/mixins' as mdc-tab-mixins;
@use '@material/tab-bar' as mdc-tab-bar;
@use '@material/icon-button' as mdc-icon;
@use 'core/palette' as ftd-colors;
@use 'core/typography' as ftd-typography;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: mat.get-color-from-palette(map.get($config, primary));
  $accent: mat.get-color-from-palette(map.get($config, accent));
  $warn: mat.get-color-from-palette(map.get($config, warn));
  $foreground: map.get($config, foreground);

  @include mat.private-using-mdc-theme($config) {
    .mat-mdc-tab,
    .mat-mdc-tab-link {
      &:not(.mat-mdc-tab-disabled) {
        @include mdc-tab-mixins.text-label-color(ftd-colors.$ftd-white);
      }

      // MDC seems to include a background color on tabs which only stands out on dark themes.
      // Disable for now for backwards compatibility. These styles are inside the theme in order
      // to avoid CSS specificity issues.
      background-color: ftd-colors.$ftd-bg-elevation;
      transition: all 0.3s ease-out;

      &.mat-mdc-tab-disabled {
        cursor: not-allowed;
        opacity: 1;

        .mdc-tab__ripple::before,
        .mat-ripple-element {
          background-color: ftd-colors.$ftd-bg-elevation;
        }

        ftd-tab-label li {
          opacity: 0.5;
        }
      }
    }

    @include _palette-styles($primary);

    .mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {
      &.mat-accent {
        @include _palette-styles($accent);
      }

      &.mat-warn {
        @include _palette-styles($warn);
      }
    }

    .mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {
      &.mat-background-primary {
        @include _background(primary, on-primary);
      }

      &.mat-background-accent {
        @include _background(secondary, on-secondary);
      }

      &.mat-background-warn {
        @include _background(error, on-error);
      }
    }

    .mat-mdc-tab-header-pagination-chevron {
      @include mdc-theme.prop(border-color, surface);
    }
  }
}

@mixin _background($background-color, $foreground-color) {
  $background-value: mdc-theme-color.prop-value($background-color);
  $foreground-value: mdc-theme-color.prop-value($foreground-color);

  --mat-mdc-tab-header-with-background-background-color: #{$background-value};
  --mat-mdc-tab-header-with-background-foreground-color: #{$foreground-value};
}

@mixin _palette-styles($color) {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    &:not(.mat-mdc-tab-disabled) {
      @include mdc-tab-mixins.active-text-label-color(ftd-colors.$ftd-white);
      @include mdc-tab-indicator-theme.theme-styles(
        (
          active-indicator-color: transparent,
        )
      );
    }
  }

  .mdc-tab__ripple::before,
  .mat-mdc-tab .mat-ripple-element,
  .mat-mdc-tab-header-pagination .mat-ripple-element,
  .mat-mdc-tab-link .mat-ripple-element {
    @include mdc-theme.prop(background-color, surface);
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-tab.without-ripple($query: mat.$private-mdc-typography-styles-query);
    @include mdc-tab-indicator.core-styles($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  .mat-mdc-tab-header {
    .mdc-tab {
      margin: 0;
      padding-left: 24px;
      padding-right: 24px;

      @include mdc-tab-mixins.height(54px);
    }

    .mdc-tab--active {
      @include mdc-theme.prop(background-color, ftd-colors.$ftd-bg-medium);
    }

    .mat-mdc-tab .mdc-tab__content,
    .mat-mdc-tab .mdc-tab__text-label {
      @include mdc-theme.prop(flex-grow, 1);
    }

    .mdc-tab__content {
      pointer-events: all;
    }
  }

  .mat-mdc-tab-body {
    background-color: ftd-colors.$ftd-bg-medium;
    padding: 24px;
  }
}

// FTD-TAB-LABEL implemented globally at TAB level to avoid CSS duplication or collision issues.
@mixin private-ftd-tab-label($typography) {
  width: 100%;

  div.ftd-tab-label {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 100%;
    min-height: 54px;

    .ftd-tab-title-container {
      margin-right: 30px;

      ul {
        align-items: center;
        display: inline-flex;
        justify-content: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;

        li:not(:last-child) {
          padding-right: 5px;
        }

        .title {
          @include mat.typography-level($typography, 'body-1');
          @include mdc-theme.prop(color, surface);
        }

        .info {
          margin-bottom: -2px;

          mat-icon {
            @include mdc-icon.icon-size(20px, 20px, 0);
          }
        }
      }
    }

    div.ftd-tab-label-info {
      align-items: center;
      display: flex;

      div.percentage {
        @include mat.typography-level($typography, 'caption');
        @include mdc-theme.prop(color, ftd-colors.$ftd-grey-400);
      }

      #tab-actions-divider {
        border-left: 1px solid ftd-colors.$ftd-grey-400;
        height: 16px;
        margin: 0 8px 0 4px;
      }
    }
  }
}

@mixin ftd-tabs($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  .ftd-tabs-panel {
    @include mat.private-check-duplicate-theme-styles($theme, 'ftd-tabs') {
      $color: mat.get-color-config($theme);
      $density: mat.get-density-config($theme);
      $typography: mat.get-typography-config($theme);

      @if $color != null {
        @include color($color);
      }

      @if $density != null {
        @include density($density);
      }

      @if $typography != null {
        @include typography($typography);

        ftd-tab-label {
          @include private-ftd-tab-label($typography);
        }
      }
    }
  }
}
