@use 'sass:map';
@use '@material/button/button' as mdc-button;
@use '@material/button/button-theme' as mdc-button-theme;
@use '@material/button/button-text-theme' as mdc-button-text-theme;
@use '@material/button/button-filled-theme' as mdc-button-filled-theme;
@use '@material/button/button-protected-theme' as mdc-button-protected-theme;
@use '@material/button/button-outlined-theme' as mdc-button-outlined-theme;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@angular/material' as mat;
@use 'private' as button-theme-private;
@use 'core/palette';

@mixin _button-variant($color) {
  @include mdc-button-text-theme.theme(
    (
      label-text-color: $color,
    )
  );
}

@mixin _unelevated-button-variant($foreground, $background) {
  @include mdc-button-filled-theme.theme(
    (
      container-color: $background,
      label-text-color: $foreground,
    )
  );
}

@mixin _raised-button-variant($foreground, $background) {
  @include mdc-button-protected-theme.theme(
    (
      container-color: $background,
      label-text-color: $foreground,
    )
  );
}

@mixin _outlined-button-variant($color) {
  @include mdc-button-outlined-theme.theme(
    (
      label-text-color: $color,
    )
  );
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  @include mat.private-using-mdc-theme($config) {
    $is-dark: map.get($config, is-dark);
    $on-surface: palette.$ftd-white;
    $surface: palette.$ftd-white;
    $disabled-ink-color: rgba($on-surface, if($is-dark, 0.5, 0.38));
    $disabled-container-color: rgba($on-surface, 0.12);
    $primary: palette.$ftd-white;
    $on-primary: palette.$ftd-primary;
    $secondary: palette.$ftd-info-200;
    $on-secondary: palette.$ftd-info-200;
    $error: palette.$ftd-risk-100;
    $on-error: palette.$ftd-risk-100;

    .mat-mdc-button {
      &.mat-unthemed {
        @include _button-variant($on-surface);
      }

      &.mat-primary {
        @include _button-variant($primary);
      }

      &.mat-accent {
        @include _button-variant($secondary);
      }

      &.mat-warn {
        @include _button-variant($error);
      }

      @include button-theme-private.apply-disabled-style {
        @include mdc-button-text-theme.theme(
          (
            disabled-label-text-color: $disabled-ink-color,
            label-text-color: $disabled-ink-color,
          )
        );
      }
    }

    .mat-mdc-unelevated-button {
      &.mat-unthemed {
        @include _unelevated-button-variant($on-surface, $surface);
      }

      &.mat-primary {
        @include _unelevated-button-variant($on-primary, $primary);
      }

      &.mat-accent {
        @include _unelevated-button-variant($on-secondary, $secondary);
      }

      &.mat-warn {
        @include _unelevated-button-variant($on-error, $error);
      }

      @include button-theme-private.apply-disabled-style {
        @include mdc-button-filled-theme.theme(
          (
            // We need to pass both the disabled and enabled values, because the enabled
            // ones apply to anchors while the disabled ones are for buttons.
            disabled-container-color: $disabled-container-color,
            disabled-label-text-color: $disabled-ink-color,
            container-color: $disabled-container-color,
            label-text-color: $disabled-ink-color
          )
        );
      }
    }

    .mat-mdc-raised-button {
      &.mat-unthemed {
        @include _raised-button-variant($on-surface, $surface);
      }

      &.mat-primary {
        @include _raised-button-variant($on-primary, $primary);
      }

      &.mat-accent {
        @include _raised-button-variant($on-secondary, $secondary);
      }

      &.mat-warn {
        @include _raised-button-variant($on-error, $error);
      }

      @include button-theme-private.apply-disabled-style {
        @include mdc-button-protected-theme.theme(
          (
            // We need to pass both the disabled and enabled values, because the enabled
            // ones apply to anchors while the disabled ones are for buttons.
            disabled-container-color: $disabled-container-color,
            disabled-label-text-color: $disabled-ink-color,
            container-color: $disabled-container-color,
            label-text-color: $disabled-ink-color,
            container-elevation: 0
          )
        );
      }
    }

    .mat-mdc-outlined-button {
      @include mdc-button-outlined-theme.theme(
        (
          outline-color: rgba(mdc-theme-color.prop-value(on-surface), 0.12),
        )
      );

      &.mat-unthemed {
        @include _outlined-button-variant($on-surface);
      }

      &.mat-primary {
        @include _outlined-button-variant($primary);
      }

      &.mat-accent {
        @include _outlined-button-variant($secondary);
      }

      &.mat-warn {
        @include _outlined-button-variant($error);
      }

      @include button-theme-private.apply-disabled-style {
        @include mdc-button-outlined-theme.theme(
          (
            // We need to pass both the disabled and enabled values, because the enabled
            // ones apply to anchors while the disabled ones are for buttons.
            label-text-color: $disabled-ink-color,
            disabled-label-text-color: $disabled-ink-color,
            outline-color: rgba($on-surface, 0.12),
            disabled-outline-color: rgba($on-surface, 0.12)
          )
        );
      }
    }

    // Ripple colors
    .mat-mdc-button,
    .mat-mdc-outlined-button {
      @include button-theme-private.ripple-theme-styles($config, false);
    }

    .mat-mdc-raised-button,
    .mat-mdc-unelevated-button {
      @include button-theme-private.ripple-theme-styles($config, true);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.private-typography-to-2018-config(mat.get-typography-config($config-or-theme));

  @include mat.private-using-mdc-typography($config) {
    @include mdc-button.without-ripple($query: mat.$private-mdc-typography-styles-query);
  }
}

@mixin density($config-or-theme) {
  $density-scale: mat.get-density-config($config-or-theme);

  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button {
    // Use `mat-mdc-button-base` to increase the specificity over the button's structural styles.
    &.mat-mdc-button-base {
      @include mdc-button-theme.density($density-scale, $query: mat.$private-mdc-base-styles-query);
      @include button-theme-private.touch-target-density($density-scale);
    }
  }
}

@mixin ftd-button($theme-or-color-config) {
  $theme: mat.private-legacy-get-theme($theme-or-color-config);

  @include mat.private-check-duplicate-theme-styles($theme, 'mat-button') {
    $color: mat.get-color-config($theme);
    $density: mat.get-density-config($theme);
    $typography: mat.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }

    @if $density != null {
      @include density($density);
    }

    @if $typography != null {
      @include typography($typography);
    }
  }
}
